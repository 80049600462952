import React, { useEffect, useState } from 'react'
import {
    Background, Menu, MenuWrapper, Content, Title, InputField, Label, Row, Submit, Heading,
    AddressWrapper, SelectWrapper, Edit, Wrapper, LabelDesciption, TrackingLink
} from './styles'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Actions from '../../redux/actions'
import util from '../../utils/common'
import Alert from 'react-bootstrap/Alert'
import Pencil from '../../assets/pencil.svg'
import AutoComplete from '../../Components/AutoComplete'
// import Loader from 'react-loader-spinner'
import firebase from '../../firebase'
import Select from 'react-select'
import Modal from '../../Components/Modal'
import Rating from '../../Components/Rating'
import StripeCard from '../../Components/StripeCard'

const Profile = props => {

    const [error, setError] = useState(false)
    const [title, setTitle] = useState('Personal')
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [address, setAddress] = useState('')
    const [cards, setCards] = useState([])
    // const [unit, setUnit] = useState('')
    const [editMode, setEditMode] = useState(false)
    const [editedAddress, setEditedAddress] = useState(false)
    const [purchases, setPurchases] = useState([])
    const userData = util.getResponseKey(['app', 'user', 'data'], props)
    const [loading, setLoading] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [modalData, setModalData] = useState(false)
    const [showAddCard, setShowAddCard] = useState(false)

    useEffect(() => {
        try {
            firebase.auth().onAuthStateChanged(_ => {
                firebase.auth().currentUser ? firebase.auth().currentUser.getIdToken().then(token => {
                }) : props.history.push('/login')
            })
        } catch (e) {
            props.history.push('/login')
        }

        if (userData) {
            setName(userData.Name)
            setEmail(userData.email)
            setAddress(userData.Address)

            // Filter Duplicate Cards
            var temp = []
            userData.cards.forEach(element => !temp.includes(element) && temp.push(element))
            if (temp.length > 0) {
                setCards(temp.map(item => ({ value: item, label: item })))
            }
        }

        if (util.getResponseKey(['app', 'user', 'err'], props)) {
            setError(props.app.user.err)
        }
    }, [props.app.user])

    useEffect(() => {
        const purchaseData = props.app.purchases
        if (purchaseData) {
            if (purchaseData.err) {
                setLoading(false)
                setError(purchaseData.err)
                return
            }
            setLoading(false)
            setPurchases(purchaseData.data)

        }
    }, [props.app.purchases])

    useEffect(() => {
        if (title === 'Logout') {
            firebase.auth().signOut().then(_ => props.history.push('/'))
            props.actions.ClearUser()
        }
        if (title === 'Purchases') {
            setLoading(true)
            props.actions.GetPurhcases({})
        }
    }, [title])

    useEffect(() => {
        if (props.app.leaveReview) {
            setShowModal(false)
            setModalData(false)
            if (props.app.leaveReview.err) {
                setError(props.app.leaveReview.err)
            }
        }
    }, [props.app.leaveReview])

    useEffect(() => {
        if (props.app.addCard) {
            if (props.app.addCard.err) {
                setError(props.app.addCard.err)
                props.actions.ClearCard()
            } else {
                setShowAddCard(false)
            }
        }
    }, [props.app.addCard])


    if (error) {
        setTimeout(() => { setError(false) }, 4000)
    }
    const pages = ['Personal', 'Purchases', 'Logout']

    const makeCardDefault = swapCard => {
        // Make that swapCard first element
        const newCards = cards.filter(item => item.value !== swapCard)
        setCards([{ label: swapCard, value: swapCard }, ...newCards])
    }

    const submitReview = () => {
        // Make that swapCard first element
        if (!modalData.Review || !modalData.Review.Rating || !modalData.Review.Comment) {
            setError('Please fill Rating and leave a comment')
            return
        }

        props.actions.LeaveReview({ data: modalData.Review, id: modalData.id })
    }

    const handleAddCard = token => {
        props.actions.AddCard({ token })
    }

    return (
        <Background id={'background'}>
            <Title main> {`Welcome ${name}`} </Title>
            {error !== false && !modalData && <Alert variant={'info'}> {error} </Alert>}
            <Background row >
                <Modal modalIsOpen={showModal} setIsOpen={setShowModal}>
                    <Row column style={{ marginTop: '20px' }}>
                        {error !== false && <Alert variant={'info'}> {error} </Alert>}
                        <Label style={{ color: 'black' }}> Review </Label>
                        <Row change style={{ marginTop: '20px' }}>
                            <LabelDesciption> Rating: </LabelDesciption>
                            <Rating style={{ width: '350px', marginLeft: '-20px' }} onChange={val => setModalData({ ...modalData, Review: { ...modalData.Review, Rating: val } })} value={modalData.Review && modalData.Review.Rating || 0} />
                        </Row>
                        <Row change style={{ marginTop: '20px' }}>
                            <LabelDesciption> Comment: </LabelDesciption>
                            <InputField onChange={({ target }) => setModalData({ ...modalData, Review: { ...modalData.Review, Comment: target.value } })} value={modalData.Review && modalData.Review.Comment} />
                        </Row>
                    </Row>
                    <Row style={{ marginBottom: '20px' }}>
                        <Submit onClick={submitReview} disabled={modalData.Review && (!modalData.Review.Rating || !modalData.Review.Comment)}>Submit</Submit>
                    </Row>

                </Modal>
                <MenuWrapper>
                    {pages.map(item => <Menu key={item} selected={title === item} onClick={() => setTitle(item)}>{item}</Menu>)}
                </MenuWrapper>
                <Content>
                    {/* <Title> {title} </Title> */}
                    {title === 'Personal' ?
                        <>
                            {/* <Row change>
                                <Label> Name: </Label>
                                <InputField readOnly value={name}></InputField>
                            </Row> */}
                            <Wrapper>
                                <Label> Email: </Label>
                                <InputField readOnly value={email} />
                            </Wrapper>
                            <Wrapper>
                                <AddressWrapper>
                                    <Label address={!editMode}> Address: </Label>
                                    {!editMode && <Edit src={Pencil} onClick={() => setEditMode(true)} />}
                                </AddressWrapper>
                                <InputField onKeyDown={() => !editedAddress && setEditedAddress(true)} readOnly={!editMode} onBlur={() => setTimeout(() => setEditedAddress(false), 500)} placeholder={editMode ? 'Start typing your address...' : null} onChange={({ target }) => editMode && setAddress(target.value)} value={address} />
                            </Wrapper>
                            {/* {editMode && <Row change>
                                <Label> Apt No/Unit: </Label>
                                <InputField placeholder={'Unit 4...'} onChange={({ target }) => editMode && setUnit(target.value)} value={unit} />
                            </Row>} */}
                            {editMode && <Wrapper noMarginTop>
                                <Label> </Label>
                                <AutoComplete profile address={address} setAddress={setAddress} setEditedAddress={() => { }} editedAddress={editedAddress} />
                            </Wrapper>}
                            {cards.length > 0 && !showAddCard && <Wrapper>
                                <Label> Default Card: </Label>
                                <SelectWrapper>
                                    <Select onChange={({ value }) => { if (cards.length > 1) { makeCardDefault(value); setEditMode(true) } }} options={cards} defaultValue={cards[0]} />
                                </SelectWrapper>
                            </Wrapper>}
                            {showAddCard &&
                                <Wrapper>
                                    <StripeCard onSubmit={token => handleAddCard(token)} onCancel={() => setShowAddCard(false)} />
                                </Wrapper>
                            }
                            {!showAddCard && <Wrapper>
                                <Submit onClick={() => setShowAddCard(true)} > Add a card</Submit>
                            </Wrapper>}
                            {editMode && <Row>
                                <Submit onClick={() => { /*address && setUnit('') */; address && props.actions.UpdateProfile({ defaultCard: cards.length > 0 && cards[0].value, Name: name, Address: /*unit + '/' +*/ address }); setEditMode(false) }}> Update </Submit>
                                <Submit secondary onClick={() => { setAddress(userData.Address); setEditMode(false) }}> Cancel </Submit>
                            </Row>}

                        </>
                        : title === 'Purchases' ?
                            <Row column>
                                {purchases.length > 0 ? purchases.map(purchase =>
                                    <div key={purchase.id} style={{ padding: '12px', borderRadius: '12px', flexDirection: 'column', display: 'flex', width: '100%', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', marginBottom: '10px' }}>
                                        <Row change>
                                            <img alt={`image for ${purchase.id}`} src={purchase.CoverImage} style={{ marginLeft: 'auto', marginTop: 'auto', marginBottom: 'auto', marginRight: 'auto', width: '120px', height: '120px' }} />
                                            {/* <LabelHeading> Title: </LabelHeading> */}
                                            <Row column>
                                                <LabelDesciption noWidth Title Center> {purchase.Title} </LabelDesciption>
                                                {purchase.Status === 1 && <>
                                                    <Row change>
                                                        <LabelDesciption Title style={{ width: '100px' }}> {`Provider:`} </LabelDesciption>
                                                        <LabelDesciption > {`${purchase.Shipping.Provider}`} </LabelDesciption>
                                                    </Row>
                                                    {/* <Row change>
                                                        <LabelDesciption Title style={{ width: '100px' }}> {`Tracking Number:`} </LabelDesciption>
                                                        <LabelDesciption  > {`${purchase.Shipping.TrackingNumber}`} </LabelDesciption>
                                                    </Row> */}
                                                    {purchase.Shipping.TrackingUrl && <Row change>
                                                        <LabelDesciption Title style={{ width: '100px' }}> {`Tracking Url:`} </LabelDesciption>
                                                        <TrackingLink target='_blank' href={purchase.Shipping.TrackingUrl} > {`${purchase.Shipping.TrackingUrl}`} </TrackingLink>
                                                    </Row>}

                                                </>}
                                                {purchase.qty > 1 && <Row change>
                                                    <LabelDesciption Title style={{ width: '100px' }}> {`Quantity:`} </LabelDesciption>
                                                    <LabelDesciption > {`${purchase.qty}`} </LabelDesciption>
                                                </Row>}
                                                <Row change>
                                                    <LabelDesciption Title style={{ width: '100px' }}> {`Order Id:`} </LabelDesciption>
                                                    <LabelDesciption > {purchase.id} </LabelDesciption>
                                                </Row>
                                                <Row change>
                                                    <LabelDesciption Title style={{ width: '100px' }}> {`Paid:`} </LabelDesciption>
                                                    <LabelDesciption > {`$${purchase.Price + (purchase.ShippingPrice || 0)}`} </LabelDesciption>
                                                </Row>
                                                <Row change>
                                                    <LabelDesciption Title style={{ width: '100px' }}> {`Date:`} </LabelDesciption>
                                                    <LabelDesciption > {purchase.Created} </LabelDesciption>
                                                </Row>
                                                <Row change>
                                                    <LabelDesciption Title style={{ width: '100px' }}> {`Status:`} </LabelDesciption>
                                                    <LabelDesciption active={purchase.Status >= 1} inactive={purchase.Status === 0} > {`${purchase.Status === 1 ? `Shipped on ${purchase.Shipping.Date}` : purchase.Status === 0 ? 'Awaiting shipment' : purchase.Status === 3 ? 'Reviewed' : 'Delivered'}`} </LabelDesciption>
                                                </Row>
                                                {(purchase.Status >= 2 && !util.getResponseKey(['Review', 'Comment'], purchase)) && <Row change>
                                                    <Submit secondary onClick={() => { setModalData(purchase); setShowModal(true) }}> Leave a Review </Submit>
                                                </Row>}
                                                {/* <LabelDesciption Title> {`You will receive: $${order.Price + (order.ShippingPrice || 0)}.`} </LabelDesciption> */}

                                            </Row>
                                            {purchase.Status === 0 && <LabelDesciption Center link> Contact Seller    </LabelDesciption>}
                                        </Row>
                                    </div>) :
                                    <LabelDesciption noWidth Title Center> {loading ? 'Loading' : 'No purchases'} </LabelDesciption>}
                            </Row>

                            : null}

                </Content>
            </Background>
        </Background >
    )

}


const mapStateToProps = state => ({
    app: state.App,
    listings: state.Listings,
    shop: state.Shop
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
})

export default connect(mapStateToProps,
    mapDispatchToProps)(Profile)