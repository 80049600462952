import styled, { css } from 'styled-components'
import { device } from '../../utils/device'
// NINA

export const Background = styled.div`
    margin-top: 120px;
    overflow: auto;
    @media ${device.mobile}{
        margin-top: 90px;
    }
`

export const Row = styled.div`
    margin-top: 4px;
    display: flex;
    justify-content: center;
    ${props => props.fullWidth && css`
        width: 100%;
        display: block;
    `}
    ${props => props.left && css`
       justify-content: left;
    `}

    @media ${device.mobile}{
        flex-direction: ${props => !props.always && 'column'};
    }
    
`

export const Title = styled.label`
    font-family: 'Play', sans-serif;
    color: #05386b;
    font-size: 21px;
    // text-align: center;
`

export const Price = styled.label`
    font-family: 'Play', sans-serif;
    color: black;
    font-size: 20px;
    // text-align: center;
`

export const Description = styled.label`
    display: flex;
    font-family: 'Play', sans-serif;
    color: ${props => props.subCategory ? '#6c757d' : 'black'};
    font-size: 18px;
    // text-align: center;
    ${props => props.center ? css`
        justify-content: center;
    `: css`
        justify-content: left;
    `}
    ${props => props.shipping && css`
        font-size: 14px;
        color: #6c757d;
    `}
`

export const Image = styled.img`
    width: 60px;
    margin-right: 20px;
    height: 60px;
    border-radius: 4px;
    :hover{
         cursor: ${props => !props.logo && 'pointer'};
    }
    ${props => props.logo && css`
        width: 60px;
        height: 60px;
        border-radius: 40px;
        border: 1px solid #dcdcdc;
    `}

    ${props => props.isDefault && css`
        padding: 6px;
    `}
`

export const ImageWrapper = styled.div`
    margin-left: 20px;
    margin-top: 20px;
    width: 550px;
    // height: 550px;
    // border: 1px solid #dcdcdc;
    @media ${device.mobile} {
        width: 350px;
        height: 350px;
        margin-left: auto;
        margin-right: auto;
    }
    ${props => props.singleImage && css`
        .carousel-control-prev{
            display: none;
        }
        
        .carousel-control-next{
            display: none;
        }
    `}
   .carousel-indicators{
       display: none;
   } 
  .carousel-item {
    text-align: center;
  }
  
  .parent {
    height: 550px;
    @media ${device.mobile} {
        width: 350px;
        height: 350px;
    }
  }
  
`

export const ProductSection = styled.div`
    margin-left: 20px;
    margin-top: 20px;
    justify-content: center;
    display: flex;;
    max-width: 500px;
    flex-direction: column;
    @media ${device.mobile} {
        margin-top: 60px;
        margin-top: ${props => props.moveTopDown && '140px'};
        justify-content: center;
        margin-left: 30px;
        margin-right: 30px;
    }
`

export const DescWrapper = styled.div`
    flex-direction: column;
    margin-top: 100px;
    display: none;
    @media ${device.mobile} {
        display: flex;
        justify-content: center;
        margin: 20px;
    }
`

export const SideDescWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    @media ${device.mobile} {
       display: none;
    }
`

export const Submit = styled.button`
    background: #05386b;
    border: none;
    color: #edf5e1;
    padding 8px;
    margin-top: 20px;
    font-family: Play, sans-serif;
    border-radius: 4px;
    ${props => props.secondary && css`
        margin-left: 10px;
        background: #e7717d;
        border: 1px solid #e7717d;
        color: white;;
    `}
    @media ${device.mobile} {
        margin: 10px;
    }
    align-self: center;
`

export const WrapperButton = styled.div`
    display: flex;
    @media ${device.mobile} {
       justify-content: center;
    }
`

export const QuantityWrapper = styled.div`
    @media ${device.mobile} {
        display: flex;
       justify-content: center;
    }
`

export const Img = styled.img`
    width: 100%;
    display: block;
    max-height: 550px;

    transition: all 0.3s ease 0s;
    &:hover{
        transform: scale(1.25);
    }
    margin: auto;
    @media ${device.mobile} {
        width: 350px;
        max-height: 350px;
     }
`

export const ThumbNails = styled.div`
    margin-top: 20px;
    @media ${device.mobile} {
        margin-left: 20px;
    }
    z-index: 2;
    position: relative;
`

export const Reviews = styled.label`
    margin-top: 20px;
    font-family: 'Play', sans-serif;
    color: #05386b;
    font-size: 18px;
`

export const ReviewsWrapper = styled.div`
    flex-direction: column;
    width: calc(100vw - 10vw);
    max-width: 1050px;
    margin-left: auto;
    margin-right: auto;
    padding-top: ${props => props.moveTopDown && '120px'};
    @media ${device.mobile}{
        padding-top: 0px;
        justify-content: center;
        margin-left: 20px;
        margin-right: 20px;
        width: 80%;
    }
`

export const Comments = styled.label`
    margin-top: 20px;
    font-family:s 'Play', sans-serif;
    ${props => props.name && css`
        font-weight: 550;
    `}
    ${props => props.date && css`
        margin-top: 0px;
        font-size: 14px;
    `}
`