import React, { useEffect, useState } from 'react'
import {
    Container, Block, Background, Titles, Image, Description, Row, Pricetag,
    AddCart, Section, SpaceBetween, SectionHeader, ShopName, BgImage, Heading, VerticalCenter, StyledSkeleton
} from './styles'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Actions from '../../redux/actions'
import Alert from 'react-bootstrap/Alert'
// import Loader from 'react-loader-spinner'
// import firebase from '../../firebase'
import AddCartImg from '../../assets/add_cart.svg'
import Skeleton from 'react-loading-skeleton'

const Dashboard = props => {
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)

    const tempValues = [...Array(12).keys()].map(val => ({ SubCategory: '', CoverImage: '', Price: 0, Title: '', id: val }))
    const [listings, setListings] = useState([])

    useEffect(() => {
        if (props.listings.err) {
            setError(props.listings.err)
            setLoading(false)
            return
        }

        if (props.listings.data) {
            listings !== props.listings.data && setListings(Object.entries(props.listings.data))
            listings !== props.listings.data && setLoading(false)
        }

    }, [props.listings])

    useEffect(() => {
        setLoading(true)
        setListings(Object.entries({ '': tempValues, ' ': tempValues }))
        props.actions.getListings()
    }, [props.location])

    if (error) {
        setTimeout(() => { setError(false) }, 14000)

    }
    const navigateToListing = (name, Title, item, id) => {
        if (name !== 'add-bag' && id.length > 3) {
            props.actions.SetViewListing(item);
            // console.log('Title', )
            props.history.push(`/view/${Title.trim().replace(/\s/g, '-')}?id=${id}`)
        }
    }
    return (
        <Background id={'background'}>
            <BgImage >
                <VerticalCenter>
                    {/* <Heading> Welcome to my</Heading> */}
                </VerticalCenter>
            </BgImage>

            {error !== false && <Alert variant={'info'}> {error}  {/*{link && <a href={link.url} className="alert-link">{link.title} </a>}*/}</Alert>}
            { listings.map(([sectionTitle, value]) => <Section key={sectionTitle}>
                <SpaceBetween>
                    <SectionHeader> {sectionTitle.trim().length > 0 ? sectionTitle : <Skeleton width={120} />} </SectionHeader>
                    {sectionTitle.trim().length > 0 ? <SectionHeader onClick={() => { /* props.history.push(`/shop?category=${sectionTitle}`) */ }} isAction>  SEE ALL </SectionHeader> : <Skeleton width={100} />}
                </SpaceBetween>
                <Container>
                    {value.map(({ SubCategory, CoverImage, Shop, StockRemaining, Price, Shipping, Title, id }, index) =>
                        <Block key={`${sectionTitle}-${index}`} onClick={({ target: { name } }) => navigateToListing(name, Title, value[index], id)}>
                            {Title ? <Image src={CoverImage} /> : <StyledSkeleton />}
                            <Titles href={`/view/${Title.trim().replace(/\s/g, '-')}?id=${id}`}> {Title || <Skeleton />} </Titles>
                            <Description > {SubCategory || <Skeleton />} </Description>
                            <Row>
                                <Pricetag >{Price ? `$${Price.toFixed(2)}` : <Skeleton />} </Pricetag>
                                {Price ? <AddCart src={AddCartImg} name='add-bag' onClick={() => { props.actions.addItem({ Shop, Shipping, StockRemaining, SubCategory, CoverImage, Price, Title, id }) }} /> : <Skeleton />}
                            </Row>
                            {/* <ShopName> {'By'} </ShopName> */}
                        </Block>)}
                </Container>
            </Section>)
            }
        </Background >
    )

}


const mapStateToProps = state => ({
    app: state.App,
    listings: state.Listings
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
})

export default connect(mapStateToProps,
    mapDispatchToProps)(Dashboard)