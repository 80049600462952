import { call, put, takeEvery, takeLatest, select } from 'redux-saga/effects'
import * as Actions from '../actions/action_types'
import Api from '../api'
import util from '../../utils/common'



function* fetchListings({ payload }) {
    try {
        const listingsData = yield call(Api.fetchListings, payload)
        yield put({ type: Actions.PUT_LISTINGS, ...listingsData })
    } catch (e) {
        yield put({ type: Actions.PUT_LISTINGS_ERROR, ...e.response.data })
    }
}

function* fetchListingsId({ payload }) {
    try {
        const listingsData = yield call(Api.fetchListingsId, payload)
        yield put({ type: Actions.PUT_VIEW_LISTING, ...listingsData })
    } catch (e) {
        yield put({ type: Actions.PUT_VIEW_LISTING, ...e.response.data })
    }
}



function* listingsSaga() {
    yield takeLatest(Actions.GET_LISTINGS, fetchListings)
    yield takeLatest(Actions.GET_LISTINGS_ID, fetchListingsId)
}

export default listingsSaga