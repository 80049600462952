import styled, { css, keyframes, createGlobalStyle } from 'styled-components'
import { device } from '../../utils/device'
import Modal from 'react-modal'

export const Wrapper = styled.div`
    display: flex;
    
`

export const ModalStyled = styled(Modal)`
    max-width: 900px;
    marginLeft: auto;
    background: white;
    margin-top: 250px;
    border: 1px solid #dcdcdc;
    border-radius: 12px;
    marginRight: auto;
    @media ${device.mobile}{
        width: 95%;
        margin-top: 100px;
    }
 `

export const GlobalStyle = createGlobalStyle`
    .ReactModal__Overlay {
        overflow-y: scroll;
    }
    .ReactModal__Overlay--after-open {
        overflow-y: scroll;
    }
`

export const ThumbImageDel = styled.img`
    width: 27px;
    margin-left: auto;
    margin-right: 20px;
`
export const CloseWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
`
