import * as actionType from './action_types'

export const getListings = (data) => {
  return {
    type: actionType.GET_LISTINGS,
    payload: data
  }
}

export const getListingById = (data) => {
  return {
    type: actionType.GET_LISTINGS_ID,
    payload: data
  }
}

export const SetViewListing = (data) => {
  return {
    type: actionType.PUT_VIEW_LISTING,
    data,
    err: null
  }
}

export const ClearViewListing = () => {
  return {
    type: actionType.CLEAR_VIEW_LISTING,
    payload: null,
    err: null
  }
}