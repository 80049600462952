import React, { useEffect, useState } from 'react'
import {
    Background, Title, Row, TotalSection, LabelDesciption, QuantityWrapper, SectionLabels, InputField, Submit
} from './styles'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Actions from '../../redux/actions'
import util from '../../utils/common'
import Alert from 'react-bootstrap/Alert'
// import Loader from 'react-loader-spinner'
import firebase from '../../firebase'
import Quantity from '../../Components/Quantity'
import DeleteImage from '../../assets/delete.svg'
import Login from '../../PageComponents/Login'

import { loadStripe } from '@stripe/stripe-js'
import { StripeKey } from '../../utils/constants'
import AutoComplete from '../../Components/AutoComplete'

const stripePromise = loadStripe(StripeKey)

const Cart = props => {

    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (util.getResponseKey(['app', 'user', 'data'], props)) {
            props.actions.retrieveStripeAccount()
            // setLoading(false)
        }

        if (util.getResponseKey(['app', 'user', 'err'], props)) {
            setError(props.app.user.err)
            setLoading(false)
        }
    }, [props.app.user])

    useEffect(() => {
        if (util.getResponseKey(['shop', 'retrieveStripeAccount', 'data'], props)) {
            const accountData = util.getResponseKey(['shop', 'retrieveStripeAccount', 'data', 'account'], props)
            if (!accountData.charges_enabled) {
                setError('Stripe Onboarding incomplete. Redirecting...')
                setTimeout(() => { props.history.push('/my-shop') }, 3000)
                return
            }
            if (!accountData.payouts_enabled) {
                console.log('need to provide identity verification docs')
            }
            // props.history.push('/my-shop')
            setLoading(false)
        }

        if (util.getResponseKey(['shop', 'retrieveStripeAccount', 'err'], props)) {
            setError(props.shop.retrieveStripeAccount.err)
            setLoading(false)
        }
    }, [props.shop.retrieveStripeAccount])


    if (error) {
        setTimeout(() => { setError(false) }, 4000)
    }

    if (loading) {
        return (
            <Background id={'background'}>
                <div>Loading</div>
            </Background>
        )
    }


    return (
        <Background id={'background'}>
            {error !== false && <Alert variant={'info'}> {error} </Alert>}
            <Row column main>


            </Row>
        </Background>
    )

}


const mapStateToProps = state => ({
    app: state.App,
    shop: state.Shop,
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
})

export default connect(mapStateToProps,
    mapDispatchToProps)(Cart)

export { stripePromise }