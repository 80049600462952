import { call, put, takeEvery, takeLatest, select } from 'redux-saga/effects'
import * as Actions from '../actions/action_types'
import Api from '../api'
import util from '../../utils/common'


const purchases = state => state.App.purchases.data

function* getUser({ payload }) {
    try {
        const userData = yield call(Api.getUser, payload)
        yield put({ type: Actions.PUT_USER, ...userData })
    } catch (e) {
        yield put({ type: Actions.PUT_USER_ERROR, ...e.response.data })
    }
}

function* signupUser({ payload }) {
    try {
        const userData = yield call(Api.signupUser, payload)
        yield put({ type: Actions.PUT_SIGNUP_USER, ...userData })
    } catch (e) {
        yield put({ type: Actions.PUT_SIGNUP_USER_ERROR, ...e.response.data })
    }
}

function* updateUser({ payload }) {
    try {
        const userData = yield call(Api.updateUser, payload)
        yield put({ type: Actions.PUT_UPDATE_USER, ...userData })
    } catch (e) {
        yield put({ type: Actions.PUT_UPDATE_USER, ...e.response.data })
    }
}

function* verifyCartPrice({ payload }) {
    try {
        const userData = yield call(Api.verifyCartPrice, payload)
        yield put({ type: Actions.PUT_VERIFY_CART_ITEMS_PRICE, ...userData })
    } catch (e) {
        yield put({ type: Actions.PUT_VERIFY_CART_ITEMS_PRICE, ...e.response.data })
    }
}

function* checkout({ payload }) {
    try {
        const userData = yield call(Api.checkout, payload)
        yield put({ type: Actions.PUT_CHECKOUT, ...userData })
    } catch (e) {
        yield put({ type: Actions.PUT_CHECKOUT, ...e.response.data })
    }
}

function* checkoutSuccess({ payload }) {
    try {
        const userData = yield call(Api.checkoutSuccess, payload)
        yield put({ type: Actions.PUT_VERIFY_CHECKOUT_SUCCESS, ...userData })
    } catch (e) {
        yield put({ type: Actions.PUT_VERIFY_CHECKOUT_SUCCESS, ...e.response.data })
    }
}

function* checkoutFailed({ payload }) {
    try {
        const userData = yield call(Api.checkoutFailed, payload)
        yield put({ type: Actions.PUT_VERIFY_CHECKOUT_FAILED, ...userData })
    } catch (e) {
        yield put({ type: Actions.PUT_VERIFY_CHECKOUT_FAILED, ...e.response.data })
    }
}

function* getPurchases({ payload }) {
    try {
        const userData = yield call(Api.getPurchases, payload)
        yield put({ type: Actions.PUT_GET_PURCHASES, ...userData })
    } catch (e) {
        yield put({ type: Actions.PUT_GET_PURCHASES, ...e.response.data })
    }
}

function* leaveReview({ payload }) {
    try {
        const userData = yield call(Api.leaveReview, payload)


        var purchasesList = yield select(purchases)

        yield put({ type: Actions.PUT_LEAVE_REVIEW, ...userData })
        if (!userData.err) {
            const purchasesMod = purchasesList.map(purchase => purchase.id === payload.id ? { ...purchase, Status: 3 } : purchase)
            yield put({ type: Actions.PUT_GET_PURCHASES, ...{ data: purchasesMod, err: null } })
        }
    } catch (e) {
        console.log('e', e)
        yield put({ type: Actions.PUT_LEAVE_REVIEW, ...e.response.data })
    }
}

function* addCard({ payload }) {
    try {
        const userData = yield call(Api.addCard, payload)
        yield call(getUser, { payload: {} })
        yield put({ type: Actions.PUT_ADD_CARD, ...userData })
    } catch (e) {
        console.log('e', e)
        yield put({ type: Actions.PUT_ADD_CARD, ...e.response.data })
    }
}



function* listingsSaga() {
    yield takeLatest(Actions.GET_USER, getUser)
    yield takeLatest(Actions.SIGNUP_USER, signupUser)
    yield takeLatest(Actions.UPDATE_USER, updateUser)
    yield takeLatest(Actions.VERIFY_CART_ITEMS_PRICE, verifyCartPrice)
    yield takeLatest(Actions.CHECKOUT, checkout)
    yield takeLatest(Actions.VERIFY_CHECKOUT_SUCCESS, checkoutSuccess)
    yield takeLatest(Actions.VERIFY_CHECKOUT_FAILED, checkoutFailed)
    yield takeLatest(Actions.GET_PURCHASES, getPurchases)
    yield takeLatest(Actions.LEAVE_REVIEW, leaveReview)
    yield takeLatest(Actions.ADD_CARD, addCard)
}

export default listingsSaga