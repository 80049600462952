import React from 'react'
import { Route, Switch, BrowserRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Actions from './redux/actions'
import Dashboard from './PageComponents/Dashboard'
import Shop from './PageComponents/Shop'
import Profile from './PageComponents/Profile'
import MyShop from './PageComponents/MyShop'
import Login from './PageComponents/Login'
import Cart from './PageComponents/Cart'
import StripeOnboarding from './PageComponents/StripeOnboarding'
import Checkout from './PageComponents/Checkout'
import View from './PageComponents/View'
import AddListing from './PageComponents/AddListing'
import Header from './Components/Header'
import Footer from './Components/Footer'

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import { createBrowserHistory } from 'history'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor } from './redux/store'

const history = createBrowserHistory()

const App = (prop) => {
  document.getElementById('body').style.overflow = prop.app.menuOpen ? 'hidden' : 'scroll'
  return (
    <div className='App' /*style={{ display: 'flex', height: '100vh', flexDirection: 'column' }}*/ >
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Header history={history} />
          <Switch>
            <Route path='/shop' render={props => <Shop {...props} />} />
            <Route path='/view' render={props => <View {...props} />} />
            <Route path='/profile' render={props => <Profile {...props} />} />
            <Route path='/listing' render={props => <AddListing {...props} />} />
            <Route path='/my-shop' render={props => <MyShop {...props} />} />
            <Route path='/login' render={props => <Login {...props} />} />
            <Route exact path='/cart' render={props => <Cart {...props} />} />
            <Route exact path='/checkout' render={props => <Checkout {...props} />} />
            <Route exact path='/stripe-onboarding' render={props => <StripeOnboarding {...props} />} />
            <Route exact path='/' render={props => <Dashboard {...props} />} />
          </Switch>
          {/* <Footer history={history} /> */}
        </BrowserRouter>
      </PersistGate>
    </div>
  )
}


const mapStateToProps = state => ({
  app: state.App,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch)

})

export default connect(mapStateToProps,
  mapDispatchToProps)(App)