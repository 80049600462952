import React, { useEffect, useState } from 'react'
import {
    Background, InputField, Row, Label, Submit, Titles
} from './styles'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Actions from '../../redux/actions'
import util from '../../utils/common'
import firebase from '../../firebase'
import Alert from 'react-bootstrap/Alert'
// import Loader from 'react-loader-spinner'

const Login = props => {
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [login, setLogin] = useState(!props.fromCart)
    const [performSignup, setPerformSignup] = useState(false)
    const [email, setEmail] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [password, setPassword] = useState('')

    useEffect(() => {
        if (loading) {
            navigateToPreviousPage()
        }

    }, [props.app.user])

    useEffect(() => {
        firebase.auth().onAuthStateChanged(_ => {
            firebase.auth().currentUser && firebase.auth().currentUser.getIdToken().then(token => {
                if (!props.fromCart) {
                    navigateToPreviousPage()
                }
            })
        })
    }, [])

    useEffect(() => {
        if (performSignup) {
            if (props.app.signUp) {
                const { data, err } = props.app.signUp
                if (err) {
                    if (err === 'User already exists.') {
                        setError('Email already exists. Try logging in.')
                        setLoading(false)
                        return
                    }
                    setError(err)
                    setLoading(false)
                    return
                }
                setPerformSignup(false)
                props.actions.GetUser()
            }
        }

    }, [props.app.signUp])

    const navigateToPreviousPage = () => {
        setLoading(false)
        const backUrl = util.getResponseKey(['cart', 'backUrl'], props)
        if (backUrl) {
            props.history.push(backUrl !== '/login' ? backUrl : '/my-shop')
            props.actions.clearRedirectUrl()
        }
    }

    const handleLogin = () => {
        if (!email || !password) {
            setError('Email and password are required.')
            return
        }

        if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
            setError('Please check your email. Invalid email format.')
            return
        }
        setLoading(true)
        if (login) {
            firebase.auth().signInWithEmailAndPassword(email, password).then(_ =>
                firebase.auth().currentUser.getIdToken().then(tok => {
                    // console.log('token', tok)
                    // props.actions.GetUser()
                })).catch(e => {
                    console.log('sign in error ', e)
                    if (e.code === 'auth/user-not-found') {
                        setError('Invalid email/ password.')
                        return
                    } else {
                        if (e.message === 'The password is invalid or the user does not have a password.') {
                            setError('Invalid password. Check Credentials.')
                            return
                        }
                    }
                    setError(e.message)
                    setLoading(false)
                })

        } else {
            if (!firstName || !lastName) {
                setError('First Name and Last Name required.')
                return
            }
            props.actions.SignupUser({ firstName, lastName, name: `${firstName} ${lastName}`, email, password })
            setLoading(true)
            setPerformSignup(true)
        }
    }

    if (error) {
        setTimeout(() => { setError(false) }, 4000)
    }

    if (loading) {
        <Background id={'background'}>
            <label> Loading </label>
        </Background>
    }

    return (
        <Background fromCart={props.fromCart} id={'background'}>
            {error !== false && <Alert variant={'info'}> {error} </Alert>}
            {!props.fromCart && <Row>
                <Titles > {login ? 'Login' : 'Sign up'}</Titles>
            </Row>}
            {!login && <>
                <Row>
                    <Label >First Name:</Label>
                    <InputField onChange={(e) => setFirstName(e.target.value)} value={firstName} />
                </Row>
                <Row>
                    <Label >Last Name:</Label>
                    <InputField onChange={(e) => setLastName(e.target.value)} value={lastName} />
                </Row>
            </>}
            <Row>
                <Label > Email:</Label>
                <InputField type='email' onChange={(e) => setEmail(e.target.value)} value={email} />
            </Row>
            <Row>
                <Label> Password:</Label>
                <InputField type='password' onChange={(e) => setPassword(e.target.value)} value={password} />
            </Row>
            <Row>
                <Submit onClick={handleLogin}>{login ? 'Login' : 'Sign Up'}</Submit>
            </Row>
            <Row>
                {login ? <Label fullWidth> Dont have an account? <Label onClick={() => setLogin(false)} link> Create one.</Label> </Label> :
                    <Label fullWidth> Have an account? <Label onClick={() => setLogin(true)} link> Login</Label></Label>}
            </Row>
        </Background >
    )

}


const mapStateToProps = state => ({
    app: state.App,
    listings: state.Listings,
    cart: state.Cart
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
})

export default connect(mapStateToProps,
    mapDispatchToProps)(Login)