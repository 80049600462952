import React, { useEffect, useState } from 'react'
import { FooterContainer, MenuLabels, MenuWrapper } from './styles'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Actions from '../../redux/actions'
import CartLogo from '../../assets/shopping-bag.svg'
import ProfileLogo from '../../assets/profile.svg'
import MainLogo from '../../assets/logo.svg'
import { withRouter } from 'react-router-dom'
import firebase from 'firebase'

const Footer = props => {
    useEffect(() => {

    }, [])


    return (
        <FooterContainer {...props} id={'footer'}>
            <MenuWrapper>
                <MenuLabels>About Us</MenuLabels>
                <MenuLabels> Returns </MenuLabels>
                <MenuLabels> Contact </MenuLabels>
            </MenuWrapper>
        </FooterContainer >
    )

}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)

})

const mapStateToProps = state => ({
    menuOpen: state.App.menuOpen,
    cart: state.Cart,
    user: state.App.user,
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer))