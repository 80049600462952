import styled, { css, keyframes } from 'styled-components'
import { device } from '../../utils/device'

export const Rating  = styled.div`
    display: flex;
    height: 20px;
    margin-top: 4px;
    margin-left: 20px;
`

export const Star  = styled.img`

`