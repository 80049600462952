import styled, { css, keyframes } from 'styled-components'
import { device } from '../../utils/device'


export const FooterContainer = styled.div`
    background: #5cde95;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    height: 60px;
    @media ${device.desktop}{
        padding-left: 40px;
        padding-right: 40px;
    }
    // position: absolute;
    // bottom: 0;
    width: 100%;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
`
const fadeInUp = keyframes` 
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
    }
  
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  `
const slideLeft = keyframes` 
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
  `

const slideRight = keyframes` 
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
  `



export const MenuLabels = styled.label`
    color: #05386b;
    font-size: 21px;
    margin-top: auto;
    margin-bottom: auto;
    font-family: 'Play', sans-serif;
    text-transform: uppercase;
    :hover{
        cursor: pointer;
    }

    ${props => props.dropdown && css`
        padding: 12px;
        // border-bottom: 1px solid grey;
    `}

    animation-name: ${fadeInUp};
    animation-duration: 2s;
`

export const MenuWrapper = styled.div`
    display: flex;
    justify-content: space-evenly;
    width: 100%;
`