import React, { useEffect, useState } from 'react'
import { Wrapper, ModalStyled, GlobalStyle, ThumbImageDel, CloseWrapper } from './styles'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import DelImage from '../../assets/remove.svg'
import Actions from '../../redux/actions'

const ModalComponent = props => {
    const { children, style, modalIsOpen, setIsOpen } = props
    useEffect(() => {


    }, [])

    const customStyles = {
        content: {
            minWidth: '300px',
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    }
    ModalStyled.setAppElement('#root')

    return (
        <Wrapper style={style || {}}>
            <GlobalStyle />
            <ModalStyled
                isOpen={modalIsOpen}
                // onRequestClose={() => setIsOpen(false)}
                style={customStyles}
                contentLabel='Modal'>
                <CloseWrapper><ThumbImageDel onClick={() => setIsOpen(false)} src={DelImage} /></CloseWrapper>
                {children}
            </ModalStyled>

        </Wrapper>
    )

}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)

})

const mapStateToProps = state => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(ModalComponent)