import React, { useEffect, useState } from 'react'
import { Rating, Star } from './styles'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Actions from '../../redux/actions'
import firebase from 'firebase'
import StarImage from '../../assets/star-filled.svg'
import StarUnFilled from '../../assets/star.svg'
import StarHalf from '../../assets/star_half.svg'

const RatingComp = props => {
    const { value, onChange, style } = props
    const [qty, setQty] = useState(4)
    const [halfStar, setHalfStar] = useState(false)
    const starCount = [1, 2, 3, 4, 5]

    useEffect(() => {
        if (value % 1 === 0) {
            setQty(value)
            setHalfStar(false)
        } else {
            setHalfStar(true)
            setQty(Math.floor(value))
        }
    }, [value])

    return (
        <Rating style={style || {}}>
            {starCount.map(val => <Star onClick={() => onChange && onChange(val)} key={val} src={qty >= val ? StarImage : (qty === (val - 1) && halfStar) ? StarHalf : StarUnFilled} />)}
        </Rating>
    )

}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)

})

const mapStateToProps = state => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(RatingComp)