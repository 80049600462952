import * as actionType from '../actions/action_types'
const DEFAULT_STATE = { menuOpen: false }

export const App = (state = DEFAULT_STATE, action) => {
    const { data, err, payload } = action
    switch (action.type) {
        case actionType.SIDE_MENU_OPEN:
            return Object.assign({}, state, {
                menuOpen: payload
            })
        case actionType.PUT_USER:
            return Object.assign({}, state, {
                user: { data, err }
            })
        case actionType.PUT_UPDATE_USER:
            return Object.assign({}, state, {
                user: { data: { ...state.user.data, ...data }, err }
            })
        case actionType.PUT_SIGNUP_USER:
            return Object.assign({}, state, {
                signUp: { data, err }
            })
        case actionType.PUT_ADD_SHOP:
            return Object.assign({}, state, {
                user: { data: { ...state.user.data, shop: data }, err }
            })
        case actionType.PUT_VERIFY_CHECKOUT_SUCCESS:
            return Object.assign({}, state, {
                verifyCheckout: data
            })
        case actionType.PUT_VERIFY_CHECKOUT_FAILED:
            return Object.assign({}, state, {
                verifyCheckoutFailed: data
            })
        case actionType.PUT_GET_PURCHASES:
            return Object.assign({}, state, {
                purchases: { data, err: err }
            })
        case actionType.PUT_LEAVE_REVIEW:
            return Object.assign({}, state, {
                leaveReview: { data, err: err }
            })
        case actionType.PUT_ADD_CARD:
            return Object.assign({}, state, {
                addCard: { data, err: err }
            })
        case actionType.CLEAR_ADD_CARD:
            return Object.assign({}, state, {
                addCard: { data: null, err: null }
            })
        default:
            return state
    }
}