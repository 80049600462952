import React, { useEffect, useState } from 'react'
import { HeaderContainer, MenuIcon, SideMenuLabels, Logo, SideMenu, MenuWrapper, MenuLabels, Cart, CartCount, CartContainer, Ul } from './styles'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Actions from '../../redux/actions'
import CartLogo from '../../assets/shopping-bag.svg'
import ShopLogo from '../../assets/shop.svg'
import ProfileLogo from '../../assets/profile.svg'
import MainLogo from '../../assets/logo.png'
import Menu from '../../assets/menu.svg'
import DownArrow from '../../assets/arrow-down.svg'
import UpArrow from '../../assets/arrow-up.svg'
import { withRouter } from 'react-router-dom'
import firebase from 'firebase'
import util from '../../utils/common'

const titles = [{ name: 'Accessories', link: '/shop/?category=Accessories' },
{ name: 'Paintings', link: '/shop/?category=Paintings' },
]


const subMenuTitles = {
    Accessories: [
        { name: 'Earrings', link: '/shop/?category=Accessories&type=Earrings' },
        { name: 'Necklace', link: '/shop/?category=Accessories&type=Necklace' },
        { name: 'Bracelet', link: '/shop/?category=Accessories&type=Bracelet' },
        { name: 'Headbands', link: '/shop/?category=Accessories&type=Headbands' }
    ],
    Paintings: [
        { name: 'Charcoal', link: '/shop/?category=Paintings&type=Charcoal' },
        { name: 'Oil Color', link: '/shop/?category=Paintings&type=OilColor' },
        { name: 'Water Color', link: '/shop/?category=Paintings&type=WaterColor' },
        { name: 'Color Pencil', link: '/shop/?category=Paintings&type=ColorPencil' },
    ],
}

const Header = props => {

    const [showMenu, setShowMenu] = useState(false)
    const [showShop, setShowShop] = useState(false)
    const [activeMenu, setActiveMenu] = useState(false)
    const [cartChanged, setCartChanged] = useState(false)
    const [cartCount, setCartCount] = useState(0)

    useEffect(() => {
        firebase.auth().onAuthStateChanged(_ => {
            firebase.auth().currentUser && firebase.auth().currentUser.getIdToken().then(token => {
                props.actions.GetUser()
            })
        })
    }, [])

    useEffect(() => {
        if (util.getResponseKey(['user', 'data'], props)) {
            setShowShop(true)
        }
    }, [props.user])

    const navigateToLogin = () => {

        props.history.location.pathname !== '/login' && props.actions.setRedirectUrl(props.history.location.pathname + props.history.location.search)
        props.history.push('/login')
    }
    const cartLength = util.getResponseKey(['cart', 'items', 'length'], props)
    if (cartLength !== cartCount && cartLength > 0) {
        setCartChanged(true)
        setCartCount(cartLength)
        setTimeout(() => setCartChanged(false), 400)
    }

    return (
        <HeaderContainer>
            <MenuIcon src={Menu} onClick={() => { props.actions.SideMenuToggle(!props.menuOpen) }} />
            <SideMenu showSidebar={props.menuOpen}>
                <SideMenuLabels menuIcon > <SideMenuLabels onClick={() => { props.actions.SideMenuToggle(!props.menuOpen) }}> X </SideMenuLabels>  </SideMenuLabels>
                {titles.map(({ name, link }, index) => <div key={index} ><div style={{ display: 'flex', justifyContent: 'space-between' }}><SideMenuLabels href={link} onClick={() => { props.actions.SideMenuToggle(!props.menuOpen); props.history.push(link) }}> {name} </SideMenuLabels> {subMenuTitles[name] && <img src={activeMenu !== name ? DownArrow : UpArrow} onClick={() => { activeMenu !== name ? setActiveMenu(name) : setActiveMenu(false) }} />}</div>
                    {subMenuTitles[name] && activeMenu === name &&
                        subMenuTitles[name].map(({ name, link }) => <SideMenuLabels href={link} subMenu key={`sub-${name}`} onClick={() => { props.actions.SideMenuToggle(!props.menuOpen); props.history.push(link) }}> {name} </SideMenuLabels>)
                    }
                </div>)}
            </SideMenu>
            <a href={'/'}>
                <Logo src={MainLogo} alt={'ArtHome Logo'} />
            </a>

            <MenuWrapper>
                {titles.map(({ name, link }, index) => <MenuLabels id={`menu-${name}`} href={link} onClick={({ target }) => { target.id === `menu-${name}` && props.history.push(link) }} onMouseLeave={() => setShowMenu(0)} onMouseEnter={() => setShowMenu(index + 1)} key={index}> {name}
                    <Ul show={showMenu === index + 1} >
                        {
                            subMenuTitles[name].map(item =>
                                <MenuLabels href={item.link} name={item.name} key={`sub-${item.name}`} dropdown>{item.name}</MenuLabels>
                            )}
                    </Ul>
                </MenuLabels>)}
            </MenuWrapper>
            {/* {showShop && <CartContainer>
                
            </CartContainer>} */}
            <CartContainer>
                {showShop &&
                    <a href={'/my-shop'}><Cart shop src={ShopLogo} onClick={() => { util.getResponseKey(['user', 'data'], props) ? props.history.push('/my-shop') : navigateToLogin() }} /></a>}
                <a href={'/profile'}>
                    <Cart src={ProfileLogo}  />
                </a>
                <div style={{ marginLeft: '20px', display: 'flex' }}>
                    <a href={'/cart'}>
                        <Cart src={CartLogo} shake={cartChanged}  />
                    </a>
                    {(util.getResponseKey(['cart', 'items'], props) && props.cart.items.length > 0) && <CartCount> {props.cart.items.length}</CartCount>}
                </div>
            </CartContainer>
        </HeaderContainer >
    )

}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)

})

const mapStateToProps = state => ({
    menuOpen: state.App.menuOpen,
    cart: state.Cart,
    user: state.App.user,
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))

export { subMenuTitles }