import React, { useEffect, useState } from 'react'
import {
    Background, Menu, MenuWrapper, Content, Title, InputField, Label, Row, Submit, ContentLabel, Heading,
    LabelHeading, LabelDesciption, Image, ThumbImageDel
} from './styles'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Actions from '../../redux/actions'
import util from '../../utils/common'
import Alert from 'react-bootstrap/Alert'
// import Loader from 'react-loader-spinner'
import firebase from '../../firebase'
import ImageUploader from 'react-images-upload'
import Pencil from '../../assets/pencil.svg'
import ShopPhoto from '../../assets/shop_photo.svg'
import Modal from '../../Components/Modal'
// import Resizer from 'react-image-file-resizer'
import DeleteImage from '../../assets/remove.svg'

var storageRef = firebase.storage().ref();

const MyShop = props => {

    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [loadingListings, setLoadingListings] = useState(true)
    const [title, setTitle] = useState('Listings')

    const [ownsShop, setOwnsShop] = useState(false)
    const [editMode, setEditMode] = useState(false)

    const [shopCategory, setShopCategory] = useState('')
    const [shopName, setShopName] = useState('')
    const [shopLocation, setShopLocation] = useState('')
    const [shopLogo, setShopLogo] = useState('')
    const [shopStatus, setShopStatus] = useState(1)

    const [shopOrders, setShopOrders] = useState([])
    const [shopListings, setShopListings] = useState([])

    const [modalData, setModalData] = useState({})
    const [showModal, setShowModal] = useState(false)
    const [callStripeOnboarding, setCallStripeOnboarding] = useState(false)

    useEffect(() => {
        try {
            firebase.auth().onAuthStateChanged(_ => {
                firebase.auth().currentUser ? firebase.auth().currentUser.getIdToken().then(token => {

                    if (util.getResponseKey(['app', 'user', 'data', 'Shop'], props)) {
                        loading && props.actions.GetShopListings({})
                        setLoadingListings(true)
                    }

                }) : props.history.push('/login')
            })
        } catch (e) {
            props.history.push('/login')
        }
        if (util.getResponseKey(['app', 'user', 'data'], props)) {
            const userData = props.app.user.data
            setLoading(false)
            if (userData.Shop) {
                setOwnsShop(true)
                setShopLogo(userData.Shop.LogoUrl)
                setShopName(userData.Shop.Name)
                setShopStatus(userData.Shop.Status === 1 ? 'Active' : 'Inactive')
                setShopCategory(userData.Shop.Category)
                if (userData.stripeAccountId) {
                    if (!userData.stripeOnboarded) {
                        setCallStripeOnboarding(true)
                    }
                }
            }
        }

        if (util.getResponseKey(['app', 'user', 'err'], props)) {
            setLoading(false)
            setError(props.app.user.err)
        }
    }, [props.app.user])

    useEffect(() => {
        if (!props.shop.products) {
            return
        }
        if (props.shop.products.err) {
            setLoading(false)
            setError(props.shop.products.err)
            return
        }
        if (props.shop.products.Listings) {
            setLoadingListings(false)
            setShopListings(props.shop.products.Listings)
        }
        if (props.shop.products.Orders) {
            if (props.shop.products.Orders.length > 0) {
                if (!props.app.user.data.stripeAccountId) {
                    setCallStripeOnboarding(true)
                }
                if (props.app.user.data.pendingStripeVerification) {
                    // Verification Documents to be provided
                }
            }

            setShopOrders(props.shop.products.Orders)
        }
        // Close modal after API response for  user providing Shipping info for orders.
        showModal && setShowModal(false)
    }, [props.shop.products])

    useEffect(() => {
        const stripeAccount = util.getResponseKey(['stripeAccount'], props.shop)
        if (stripeAccount) {
            if (stripeAccount.err) {
                setError(stripeAccount.err)
                return
            }
            window.location.href = stripeAccount.data.accountLinks.url
        }
    }, [props.shop.stripeAccount])

    useEffect(() => {
        // if (title === 'Listings') {
        //     if (util.getResponseKey(['app', 'user', 'data', 'Shop'], props)) {
        //         console.log('fired select')
        //         props.actions.GetShopListings({})
        //     }
        // }

    }, [title])

    if (error) {
        setTimeout(() => { setError(false) }, 7000)
    }

    const handleUpload = (picture) => {
        if (picture.length > 0) {
            const file = picture[0]
            if (file) {
                let reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onloadend = () => {
                    setShopLogo(reader.result)

                }
            }
        }
    }

    const handleCreateShop = () => {
        if (!shopName || !shopLocation || !shopCategory) {
            setError('Please fill in all the details')
            window.scrollTo(0, 0)
            return
        }
        props.actions.AddShop({ Name: shopName, Logo: shopLogo, Location: shopLocation, Category: shopCategory })
    }

    const handleEditLogo = () => {
        if (!shopLogo) {
            setError('Need a new Shop Logo')
            window.scrollTo(0, 0)
            return
        }
        props.actions.AddShop({ Name: shopName, Logo: shopLogo, Location: shopLocation, Category: shopCategory, editMode: true })
        setEditMode(false)
    }


    const verifyShippingInfo = () => {
        if (!modalData.Shipping.Images || modalData.Shipping.Images.length === 0) {
            setError('Please provide atleast one Packaged Product Image')
            return
        }
        if (!modalData.Shipping.TrackingNumber || !modalData.Shipping.Provider) {
            setError('Please fill tracking number and provider info.')
            return
        }

        props.actions.prodvideOrderShipping({ OrderId: modalData.id, Shipping: modalData.Shipping })

    }

    const pages = ['Listings', 'Orders']

    if (loading) {
        return (
            <Background id={'background'}>
                <div>Loading</div>
            </Background>
        )
    }


    const handleShippingUpload = picture => {
        var Images = [...modalData.Shipping.Images], index = (modalData.Shipping.Images.length > 1) ? (modalData.Shipping.Images.length - 1) : 0
        for (var file of picture) {
            if (file) {
                // File size <5Mb
                if (file.size < 5000000) {
                    let reader = new FileReader()
                    reader.readAsDataURL(file)
                    reader.onloadend = () => {
                        index += 1
                        console.log('index', index)
                        if (index <= 4) {
                            Images.push(reader.result)
                            if (index >= picture.length || index === 4) {
                                setModalData({ ...modalData, Shipping: { ...modalData.Shipping, Images } })
                            }
                        }
                    }
                } else {

                    var uploadTask = storageRef.child(`products/${new Date().getTime()}`).put(file)
                    uploadTask.on('state_changed',
                        (snapshot) => {
                            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                            console.log('Upload is ' + progress + '% done')
                            switch (snapshot.state) {
                                case firebase.storage.TaskState.PAUSED: // or 'paused'
                                    break;
                                case firebase.storage.TaskState.RUNNING: // or 'running'
                                    break;
                            }
                        },
                        (error) => {
                            // Handle unsuccessful uploads
                        },
                        () => {
                            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                                index += 1
                                if (index <= 4) {
                                    Images.push(downloadURL)
                                    if (index >= picture.length || index === 4) {
                                        setModalData({ ...modalData, Shipping: { ...modalData.Shipping, Images } })
                                    }
                                }
                            })
                        })
                    // Resizer.imageFileResizer(
                    //     file,
                    //     1200,
                    //     1200,
                    //     'JPEG',
                    //     100,
                    //     0,
                    //     uri => {
                    //         index += 1
                    //         console.log('index', index)
                    //         if (index <= 4) {
                    //             Images.push(uri)
                    //             if (index >= picture.length || index === 4) {
                    //                 setModalData({ ...modalData, Shipping: { ...modalData.Shipping, Images } })
                    //             }
                    //         }
                    //     },
                    //     'base64',
                    //     600,
                    //     600,
                    // )
                }

            } else {
                setModalData({ ...modalData, Shipping: { ...modalData.Shipping, Images: [] } })
            }
        }
    }


    return (
        <Background id={'background'}>
            {/* <Title main> {`Welcome ${name}`} </Title> */}
            {error !== false && !showModal && <Alert variant={'info'}> {error} </Alert>}

            <Modal modalIsOpen={showModal} setIsOpen={setShowModal}>

                {modalData.User && <>
                    <Row change>
                        {error !== false && <Alert variant={'info'}> {error} </Alert>}
                    </Row>
                    <Row change>
                        <Label Title > {`Order of:`} </Label>
                        <LabelDesciption> {modalData.User.Name}</LabelDesciption>
                    </Row>
                    <ImageUploader
                        style={{ width: '250px', marginLeft: 'auto', marginRight: 'auto' }}
                        withIcon={false}
                        buttonText='Upload packaged Images'
                        onChange={handleShippingUpload}
                        label={`Max 4 images. \n Accepted filetypes: .jpg/jpeg, .png, .bmp`}
                        imgExtension={['.jpg', '.jpeg', '.png', '.bmp']}
                        maxFileSize={72242880}
                    />
                    <Row>
                        {modalData.Shipping.Images && modalData.Shipping.Images.map((item, ind) =>
                            <div key={ind} style={{ display: 'flex', flexDirection: 'column' }} onClick={() => setModalData({ ...modalData, Shipping: { ...modalData.Shipping, Images: modalData.Shipping.Images.filter((_, index) => index !== ind) } })} >
                                <ThumbImageDel src={DeleteImage} />
                                <Image thumb src={item} />
                            </div>

                        )}
                    </Row>
                    <Row change>
                        <Alert variant='primary'> Ensure the tracking number is correct. You cannot enter it again.</Alert>
                    </Row>
                    <Row change>
                        <Label Title> Tracking Number: </Label>
                        <InputField onChange={({ target }) => { setModalData({ ...modalData, Shipping: { ...modalData.Shipping, TrackingUrl: `https://auspost.com.au/mypost/track/#/details/${target.value}`, TrackingNumber: target.value } }) }} value={modalData.Shipping.TrackingNumber}></InputField>
                    </Row>

                    <Row change>
                        <Label Title> Tracking URL: </Label>
                        <InputField placeholder={'https://auspost.com.au/mypost/track/#/details/33GWK010343801000935001'} onChange={({ target }) => setModalData({ ...modalData, Shipping: { ...modalData.Shipping, TrackingUrl: target.value } })} value={modalData.Shipping.TrackingUrl}></InputField>
                    </Row>
                    <Row change>
                        <Label Title> Provider: </Label>
                        <InputField placeholder={'Australia Post'} onChange={({ target }) => setModalData({ ...modalData, Shipping: { ...modalData.Shipping, Provider: target.value } })} value={modalData.Shipping.Provider}></InputField>
                    </Row>
                    <Row>
                        <Submit disabled={!modalData.Shipping.TrackingNumber || !modalData.Shipping.Provider} onClick={verifyShippingInfo}> Submit </Submit>
                    </Row></>}
            </Modal>
            {ownsShop && <>
                {!editMode ? <Row>
                    <img src={shopLogo || ShopPhoto} alt={`shop logo`} style={{ width: '120px', height: '120px', border: '1px solid #dcdcdc', padding: '5px', borderRadius: '60px' }} />
                    <img src={Pencil} alt={`edit pencil`} style={{ marginTop: 'auto', marginBottom: 'auto', width: '20px', marginLeft: '0px', height: '20px' }} onClick={() => { setEditMode(true) }} />
                    <div style={{ display: 'flex', marginLeft: '20px', flexDirection: 'column' }}>
                        <LabelDesciption noWidth noMargin Title> {shopName} </LabelDesciption>
                        <LabelDesciption noWidth noMargin > {shopName} </LabelDesciption>
                    </div>
                </Row> :
                    <>
                        <ImageUploader
                            style={{ width: '250px', marginLeft: 'auto', marginRight: 'auto' }}
                            withIcon={false}
                            withPreview
                            buttonText='Upload Logo'
                            onChange={handleUpload}
                            label={'Your Shop Logo'}
                            imgExtension={['.jpg', '.jpeg', '.png', '.bmp']}
                            maxFileSize={72242880}
                            singleImage
                        />
                        <Row>
                            <Submit onClick={handleEditLogo} link> Submit Logo</Submit>
                            <Submit secondary onClick={() => { setShopLogo(props.app.user.data.Shop.LogoUrl); setEditMode(false) }} link> Cancel</Submit>
                        </Row>
                    </>}

            </>}

            {ownsShop && <MenuWrapper>
                {pages.map(item => <Menu key={item} selected={title === item} onClick={() => setTitle(item)}>{item}</Menu>)}
            </MenuWrapper>}
            <Content>
                {!editMode && <Row>
                    <Submit secondary onClick={() => props.history.push('/listing?mode=add')} link> List New Item</Submit>
                </Row>}
                {/* <Title> {title} </Title> */}

                {callStripeOnboarding && <Row column>
                    <Heading> Complete Stripe Onboarding to withdraw funds. </Heading>
                    <Submit onClick={() => { props.actions.createStripeAccount() }}> Complate Stripe Onboarding </Submit>
                </Row>}
                {!ownsShop ?

                    <>
                        <Title> Create your shop! </Title>
                        <Row change>
                            <ImageUploader
                                style={{ width: '250px', marginLeft: 'auto', marginRight: 'auto' }}
                                withIcon={false}
                                withPreview
                                buttonText='Upload Shop Logo'
                                onChange={handleUpload}
                                label={'Your Shop Logo'}
                                imgExtension={['.jpg', '.jpeg', '.png', '.bmp']}
                                maxFileSize={72242880}
                                singleImage
                            />
                        </Row>
                        <Row change>
                            <Label> Shop Name: </Label>
                            <InputField onChange={({ target }) => setShopName(target.value)} value={shopName}></InputField>
                        </Row>
                        <Row change>
                            <Label> Category: </Label>
                            <InputField placeholder={'Art/ Craft'} onChange={({ target }) => setShopCategory(target.value)} value={shopCategory}></InputField>
                        </Row>
                        <Row change>
                            <Label> Shop Location: </Label>
                            <InputField placeholder={'Melbourne, VIC'} onChange={({ target }) => setShopLocation(target.value)} value={shopLocation}></InputField>
                        </Row>
                        <Row>
                            <Submit onClick={handleCreateShop}> Create </Submit>
                        </Row>
                    </>
                    : title === 'Listings' ?
                        <Row column>

                            {shopListings.length > 0 ? shopListings.map(listing =>
                                <div key={listing.id} style={{ padding: '12px', borderRadius: '12px', flexDirection: 'column', display: 'flex', width: '100%', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', marginBottom: '10px' }}>
                                    <Row change>
                                        <img src={listing.CoverImage} alt={`image for ${listing.id.substring(0, 5)}`} style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: 'auto', marginBottom: 'auto', width: '120px', height: '120px' }} />
                                        {/* <LabelHeading> Title: </LabelHeading> */}
                                        <Row column>
                                            <LabelDesciption Title Center> {listing.Title} </LabelDesciption>
                                            <Row change>
                                                <LabelDesciption Title style={{ width: '100px' }}> {`Price:`} </LabelDesciption>
                                                <LabelDesciption style={{ width: '100px' }}> {`$${listing.Price}`} </LabelDesciption>
                                            </Row>
                                            <Row change>
                                                <LabelDesciption Title style={{ width: '100px' }}> {`In Stock:`} </LabelDesciption>
                                                <LabelDesciption style={{ width: '100px' }}> {`${listing.StockRemaining}`} </LabelDesciption>
                                            </Row>
                                            <Row change>
                                                <LabelDesciption Title style={{ width: '100px' }}> {`Status:`} </LabelDesciption>
                                                <LabelDesciption active={listing.Status === 1} inactive={listing.Status === 0} style={{ width: '100px', }}> {`${listing.Status === 1 ? 'Active' : listing.Status === 0 && 'Inactive'}`} </LabelDesciption>
                                            </Row>
                                        </Row>
                                        <LabelDesciption link onClick={() => props.history.push(`/listing?mode=edit&id=${listing.id}`)}> Edit </LabelDesciption>
                                    </Row>
                                    {/* <Row>
                                     <LabelHeading> SubCategory: </LabelHeading>
                                     <Label> {listing.SubCategory} </Label>
                                 </Row> */}
                                </div>

                            ) :
                                <div>{loadingListings ? 'Loading' : 'Start Listing'}</div>

                            }

                        </Row>

                        :
                        <>
                            <Row column>
                                {shopOrders.length > 0 &&
                                    <Row column instructions>
                                        <label style={{ textDecoration: 'underline', fontWeight: 'bold' }}>Packaging and shipping instructions: </label>
                                        <label> 1. Package your product with either bubble wrap or suitable padding.</label>
                                        <label> 2. Take pictures of the item once packaged.</label>
                                        <label> 3. Ship the product with tracking in Australia Post.</label>
                                        <label> 4. Note down the tracking number, tracking Url. (Only tracked products are allowed!)</label>
                                        <label> 5. Provide all these details in the shipping info.</label>
                                    </Row>
                                }
                                {shopOrders.length > 0 ? shopOrders.map(order =>
                                    <div key={order.id} style={{ padding: '12px', borderRadius: '12px', flexDirection: 'column', display: 'flex', width: '100%', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', marginBottom: '10px' }}>
                                        {order.PaymentStatus === 'paid' && <Row change>
                                            <img alt={`image for ${order.id}`} src={order.CoverImage} style={{ marginLeft: 'auto', marginTop: 'auto', marginBottom: 'auto', marginRight: 'auto', width: '120px', height: '120px' }} />
                                            {/* <LabelHeading> Title: </LabelHeading> */}
                                            <Row column>
                                                <LabelDesciption noWidth Title Center> {order.Title} </LabelDesciption>
                                                <Row change>
                                                    <LabelDesciption Title style={{ width: '100px' }}> {`Id:`} </LabelDesciption>
                                                    <LabelDesciption > {`${order.id}`} </LabelDesciption>
                                                </Row>
                                                <Row change>
                                                    <LabelDesciption Title style={{ width: '100px' }}> {`Date:`} </LabelDesciption>
                                                    <LabelDesciption > {`${order.Created}`} </LabelDesciption>
                                                </Row>
                                                {order.Status === 0 && <>
                                                    <Row change>
                                                        <LabelDesciption Title style={{ width: '100px' }}> {`Buyer:`} </LabelDesciption>
                                                        <LabelDesciption > {`${order.User.Name}`} </LabelDesciption>
                                                    </Row>
                                                    <Row change>
                                                        <LabelDesciption Title style={{ width: '100px' }}> {`Ship to:`} </LabelDesciption>
                                                        <LabelDesciption  > {`${order.User.Address}`} </LabelDesciption>
                                                    </Row>
                                                    <Row change>
                                                        <LabelDesciption Title style={{ width: '100px' }}> {`Quantity:`} </LabelDesciption>
                                                        <LabelDesciption > {`${order.qty}`} </LabelDesciption>
                                                    </Row>
                                                </>}
                                                <Row change>
                                                    <LabelDesciption Title style={{ width: '100px' }}> {`Amount:`} </LabelDesciption>
                                                    <LabelDesciption > {`$${((order.Price + (order.ShippingPrice || 0)) * 0.9).toFixed(2)}`} </LabelDesciption>
                                                </Row>
                                                <Row change>
                                                    <LabelDesciption Title style={{ width: '100px' }}> {`Status:`} </LabelDesciption>
                                                    <LabelDesciption active={order.Status >= 1} inactive={order.Status === 0} > {`${order.Status === 4 ? `Paid out ${order.PaidDate}` : order.Status === 3 ? 'Reviewed' : order.Status === 2 ? 'Delivered' : order.Status == 1 ? 'Shipped' : order.Status === 0 && 'Awaiting shipment'}`} </LabelDesciption>
                                                </Row>
                                                {/* <LabelDesciption Title> {`You will receive: $${order.Price + (order.ShippingPrice || 0)}.`} </LabelDesciption> */}

                                            </Row>
                                            {order.Status === 0 && <LabelDesciption Center link onClick={() => { setShowModal(true); setModalData({ ...order, Shipping: { ...modalData.Shipping, Provider: 'Australia Post' } }) }}> Provide Shipping info     </LabelDesciption>}
                                        </Row>}
                                    </div>
                                ) :
                                    <ContentLabel> {loadingListings ? 'Loading' : 'No orders'}</ContentLabel>
                                }

                            </Row>


                        </>
                }
            </Content>
        </Background >
    )

}


const mapStateToProps = state => ({
    app: state.App,
    listings: state.Listings,
    shop: state.Shop
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
})

export default connect(mapStateToProps,
    mapDispatchToProps)(MyShop)