import React, { useEffect, useState } from 'react'
import { Wrapper } from './styles'
import api from '../../redux/api'
const { autofillAddress } = api


var fetchingAPI = false, callingAPI = false

const AutoComplete = props => {
    const [editAddLocal, setEditAddLocal] = useState(true)
    const [places, setPlaces] = useState([])
    const { address, setAddress, setEditedAddress, editedAddress, profile } = props

    useEffect(() => {
        profile && !editAddLocal && setEditAddLocal(true)
        if (!profile ? editedAddress : editAddLocal) {

            if (address.length > 3) {

                if (address.length % 4 === 0 && editedAddress) {
                    fetchingAPI = true
                } else {
                    fetchingAPI = false
                }

                if (fetchingAPI && !callingAPI) {
                    callingAPI = true
                    autofillAddress({ address }).then(val => {
                        callingAPI = false
                        setPlaces(val.data.predictions.filter(item => item.description.includes('Australia')))
                    }).catch(e => console.log('e', e))
                }
            }
        }
    }, [address])

    useEffect(() => {
        !editedAddress && profile && setEditAddLocal(false)
        !editedAddress && setPlaces([])

    }, [editedAddress])

    return (
        <Wrapper>
            <div >
                {places.map(({ description }) =>
                    <div tabIndex={0} key={description} style={{ width: '350px', padding: '4px', borderLeft: '1px solid #dcdcdc', borderRight: '1px solid #dcdcdc', borderBottom: '1px solid #dcdcdc' }}
                        onClick={() => { !profile ? setEditedAddress(false) : setEditAddLocal(false); setAddress(description); setPlaces([]) }}>
                        <label style={{ fontFamily: 'Play, sans-serif' }}>{description}</label>
                    </div>
                )}
            </div>
        </Wrapper >
    )

}


export default (AutoComplete)