import styled, { css, keyframes } from 'styled-components'
import { device } from '../../utils/device'


export const Wrapper = styled.div`
    display: flex;
    width: 350px;
    @media ${device.mobile}{
        justify-content: center;
        width: auto;
    }
`