import * as actionType from '../actions/action_types'
const DEFAULT_STATE = { data: null }

export const Listings = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case actionType.PUT_LISTINGS:
            const { data, err } = action
            return Object.assign({}, state, {
                data, err
            })
        case actionType.PUT_VIEW_LISTING:
            return Object.assign({}, state, {
                viewListing: { data: action.data, err: action.err || null }
            })
        case actionType.CLEAR_VIEW_LISTING:
            return Object.assign({}, state, {
                viewListing: null
            })

        default:
            return state
    }
}