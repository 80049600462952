import React, { useEffect, useState } from 'react'
import {
    Background, Row, Image, ImageWrapper, Title, ProductSection, Description, Price, Submit, WrapperButton,
    DescWrapper, Img, QuantityWrapper, ThumbNails, SideDescWrapper, Reviews, Comments, ReviewsWrapper
} from './styles'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Actions from '../../redux/actions'
import util from '../../utils/common'
import Alert from 'react-bootstrap/Alert'
// import Loader from 'react-loader-spinner'
import Carousel from 'react-bootstrap/Carousel'
import Rating from '../../Components/Rating'
import Quantity from '../../Components/Quantity'
import Skeleton from 'react-loading-skeleton'
import Shop from '../../assets/shop.svg'
import { Helmet } from 'react-helmet'

const View = props => {
    const [error, setError] = useState(false)
    const [link, setLink] = useState(false)
    const [loading, setLoading] = useState(false)
    const [urlParams, setUrlParams] = useState(util.getUrlParams())
    const [product, setProduct] = useState({})


    const [thumbIndex, setThumbIndex] = useState(0);


    useEffect(() => {
        if (urlParams !== util.getUrlParams()) {
            setUrlParams(util.getUrlParams())
        }
    }, [props.location])

    useEffect(() => {
        const id = urlParams.id
        if (!id) {
            props.history.push('/')
            return
        }
        if (!loading) {
            props.actions.getListingById({ id: urlParams.id })
        }

        if (id !== util.getResponseKey(['viewListing', 'data', 'id'], props)) {
            setProduct({})
        }
        setLoading(true)
    }, [urlParams])

    useEffect(() => {
        if (!props.viewListing) {
            // props.actions.getListingById({ id: urlParams.id })
            return
        }
        if (props.viewListing.err) {
            setError(props.viewListing.err)
            return
        }
        if (props.viewListing.data) {
            setProduct(props.viewListing.data)
        }
    }, [props.viewListing])

    if (error) {
        setTimeout(() => { setError(false); setLink(false) }, 14000)

    }


    const addBag = () => {
        const filteredCart = props.cart.filter(item => item.id === product.id)
        if (!filteredCart.length > 0 || (filteredCart.length > 0 && product.StockRemaining >= product.qty)) {
            const { SubCategory, Shop, Shipping, CoverImage, Price, StockRemaining, Title, id } = product
            props.actions.addItem({ SubCategory, Shipping, StockRemaining, CoverImage, Price, Shop: Shop.id, Title, id, qty: product.qty || 1 })
        } else {
            setError('You already have this item in your cart.')
            setLink({ title: 'Checkout now.', url: '/cart' })
        }
    }

    return (
        <Background id={'background'}>
            <Helmet>
                <title>{window.location.href.match(/view\/(.*)(?=\?id)/)[1]}</title>
                <meta name="title" content={`${window.location.href.match(/view\/(.*)(?=\?id)/)[1]} - Arthome.com.au`} />
                <meta name="description" content={`Buy ${window.location.href.match(/view\/(.*)(?=\?id)/)[1]} online at Art Home!}`} />
                <meta itemprop="description" content={`Buy ${window.location.href.match(/view\/(.*)(?=\?id)/)[1]} online at Art Home!}`} />
                <meta property="og:description" content={`Buy ${window.location.href.match(/view\/(.*)(?=\?id)/)[1]} online at Art Home!}`} />
                <meta name="twitter:description" content={`Buy ${window.location.href.match(/view\/(.*)(?=\?id)/)[1]} online at Art Home!}`} />

            </Helmet>
            <Row fullWidth>
                {error !== false && <Alert variant={'info'}> {error}  {link && <a href={link.url} className="alert-link">{link.title} </a>}</Alert>}
            </Row>

            {!product.Title ?
                <div>Loading</div>

                :
                <>
                    <Row>
                        <ImageWrapper singleImage={product.Images && product.Images.length === 1}>
                            <Carousel activeIndex={thumbIndex} onSelect={index => setThumbIndex(index)} >
                                {product.Images && product.Images.map((item, id) => <Carousel.Item key={id}>
                                    <div className="parent d-flex justify-content-center">
                                        <Img src={item} />
                                    </div>
                                </Carousel.Item>)}
                            </Carousel>
                            {product.Images && product.Images.length > 1 && <ThumbNails>
                                {product.Images && product.Images.map((item, id) =>
                                    <Image key={id} onClick={() => setThumbIndex(id)} src={item} />
                                )}
                            </ThumbNails>}
                        </ImageWrapper>
                        <ProductSection moveTopDown={product.Images && product.Images.length > 1}>
                            <Title>
                                {product.Title}
                            </Title>
                            <Description subCategory>
                                {product.SubCategory}
                            </Description>
                            <Price>
                                {`$${product.Price.toFixed(2)}`} <Description shipping>
                                    {`Shipping: ${product.Shipping ? `$${product.Shipping.toFixed(2)}` : 'Free'}`}
                                </Description>
                            </Price>

                            <Row left always>
                                {typeof (product.Shop) === 'object' ?
                                    <Image logo isDefault={!product.Shop.LogoUrl} src={product.Shop.LogoUrl || Shop} /> :
                                    <Skeleton style={{ width: '60px', height: '60px', borderRadius: '40px' }} />
                                }
                            </Row>
                            <Row left always>
                                <Price>
                                    {product.Shop.Name || <Skeleton width={60} />}
                                </Price>
                                {product.Shop.Name ? <Rating value={product.Shop.Rating || 0} /> : <Skeleton style={{ marginLeft: '20px' }} width={100} />}

                            </Row>

                            {product.StockRemaining > 1 && <QuantityWrapper >
                                <Quantity value={product.qty || 1} maxValue={product.StockRemaining} setValue={(val) => setProduct({ ...product, qty: val })} />
                            </QuantityWrapper>}
                            <WrapperButton>
                                <Submit onClick={addBag}> ADD TO CART</Submit>
                            </WrapperButton>
                            <SideDescWrapper>
                                <Description center>
                                    <pre style={{ width: '100%', overflow: 'none', whiteSpace: 'pre-wrap' }}>{product.Description}</pre>
                                </Description>
                            </SideDescWrapper>
                        </ProductSection>

                    </Row>
                    <DescWrapper>
                        <Description center>
                            <pre style={{ width: '100%', overflow: 'none', whiteSpace: 'pre-wrap' }}>{product.Description}</pre>
                        </Description>
                    </DescWrapper>
                    <Row column>
                        {product.Reviews && product.Reviews.length > 0 && <ReviewsWrapper moveTopDown={product.Images && product.Images.length > 1}>
                            <Reviews> Reviews</Reviews>
                            {product.Reviews.map(review => <div>
                                <Comments name> {review.Name}</Comments>
                                <div>
                                    <Comments date> {review.createdDate}</Comments>
                                </div>
                                <Rating style={{ marginLeft: '0' }} value={review.Rating} />
                                <Comments> {review.Comment}</Comments>
                            </div>)}
                        </ReviewsWrapper>}
                    </Row>
                </>}
        </Background>
    )
}

const mapStateToProps = state => ({
    app: state.App,
    listings: state.Listings,
    viewListing: state.Listings.viewListing,
    cart: state.Cart.items
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
})

export default connect(mapStateToProps,
    mapDispatchToProps)(View)