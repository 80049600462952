import * as actionType from './action_types'

export const SideMenuToggle = (data) => {
  return {
    type: actionType.SIDE_MENU_OPEN,
    payload: data
  }
}

export const GetUser = (data) => {
  return {
    type: actionType.GET_USER,
    payload: data
  }
}

export const ClearUser = (data) => {
  return {
    type: actionType.PUT_USER,
    data: null,
    err: null
  }
}

export const SignupUser = (data) => {
  return {
    type: actionType.SIGNUP_USER,
    payload: data
  }
}
export const UpdateProfile = (data) => {
  return {
    type: actionType.UPDATE_USER,
    payload: data
  }
}

export const GetPurhcases = (data) => {
  return {
    type: actionType.GET_PURCHASES,
    payload: data
  }
}

export const LeaveReview = (data) => {
  return {
    type: actionType.LEAVE_REVIEW,
    payload: data
  }
}

export const AddCard = (data) => {
  return {
    type: actionType.ADD_CARD,
    payload: data
  }
}

export const ClearCard = (data) => {
  return {
    type: actionType.CLEAR_ADD_CARD,
    payload: data
  }
}
