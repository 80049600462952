import * as actionType from '../actions/action_types'
const DEFAULT_STATE = {}

export const Shop = (state = DEFAULT_STATE, action) => {
    const { data, err, payload } = action
    switch (action.type) {
        // case actionType.PUT_ADD_SHOP:
        //     return Object.assign({}, state, {
        //         shop: { data, err }
        //     })
        case actionType.PUT_SHOP_LISTINGS:
            return Object.assign({}, state, {
                products: { ...data, err }
            })
        case actionType.PUT_ADD_SHOP_LISTINGS:
            return Object.assign({}, state, {
                addedProducts: { data, err }
            })
        case actionType.PUT_ORDER_SHIPPING_INFO:
            return Object.assign({}, state, {
                addedProducts: { data, err }
            })
        case actionType.PUT_CREATE_STRIPE_ACCOUNT:
            return Object.assign({}, state, {
                stripeAccount: { data, err }
            })
        case actionType.PUT_RET_STRIPE_ACCOUNT:
            return Object.assign({}, state, {
                retrieveStripeAccount: { data, err }
            })
        default:
            return state
    }
}