import styled, { css } from 'styled-components'
import { device } from '../../utils/device'
// NINA

export const Background = styled.div`
    @media ${device.mobile}{
        flex-direction: column;
    }
    margin-top: 40px;
    overflow: auto;
    
`

export const Title = styled.label`
    font-family: 'Play', sans-serif;
    color: black;
    font-size: 25px;
    text-align: center;
    justify-content: center;
    display: flex;
    ${props => props.main && css`
        margin-top: 20px;
        font-size: 28px;
        color: #6c757d;
    `}
    @media ${device.mobile}{
        font-size: 21px;
    }
`


export const LabelDesciption = styled.label`
    font-family: 'Play', sans-serif;
    color: #6c757d;
    font-size: 18px;
    margin-top: auto;
    margin-bottom: auto;
    width: ${props => !props.price && '300px'};
    @media ${device.mobile}{
        // width: 100%;
        align-self: center;
        width: auto;
        text-align: center;
    }
    ${props => props.active && css`
        color: green;
    `}
    ${props => props.Title && css`
        color: black;
        color: #05386b;
        :hover{
            cursor: pointer;
        }
    `}
    ${props => props.link && css`
    :hover{
        cursor: pointer;
    }
        color: #05386b;
    `}
    ${props => props.price && css`
        font-size: 20px;
        color: black;
    `}
    ${props => props.qty && css`
        width: auto;
        margin-right: 10px;
    `}
`
export const Row = styled.div`
    display: flex;
    // width: 60%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    ${props => props.main && css`    
        max-width: 1000px;
    `}
    justify-content: center;
    @media ${device.mobile}{
        width: 100%;
    }
    ${props => props.column && css`
        flex-direction: column;
        // width: 50%;
        align-items: center;
    `}
    ${props => props.change && css`
        flex-direction: row;
        width: 100%;
        @media ${device.mobile}{
            flex-direction: column;
        }
    `}

    ${props => props.inverse && css`
        flex-direction: column;
        @media ${device.mobile}{
            flex-direction: row;
        }
    
    `}
`

export const Submit = styled.button`
    background: #05386b;
    border: none;
    color: #edf5e1;
    padding 8px;
    margin-top: 20px;
    font-family: Play, sans-serif;
    border-radius: 4px;
    margin-bottom: 20px;
    ${props => props.secondary && css`
        margin-left: 10px;
        background: white;
        border: 1px solid #05386b;
        color: #05386b;
    `}
    @media ${device.mobile} {
        margin: 10px;
        padding 4px;

    }
    align-self: center;
`


export const QuantityWrapper = styled.div`
    width: 300px; 
    display: flex;
    @media ${device.mobile} {
        width: auto;
        justify-content: center;   
    }
`

export const TotalSection = styled.div`
    display: flex; 
    justify-content: flex-end;
    width: 90%;
    margin-right: 20px;
    @media ${device.mobile} {
        justify-content: center;
        margin-right: 0;
    }
    margin-bottom: 20px;
`