import React, { useEffect, useState } from 'react'
import {
    Container, Block, Background, Titles, Image, Description, Row, Pricetag, AddCart, Section, SpaceBetween, SectionHeader, StyledSkeleton
} from './styles'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Actions from '../../redux/actions'
import util from '../../utils/common'
import AddCartImg from '../../assets/add_cart.svg'
import Alert from 'react-bootstrap/Alert'
// import Loader from 'react-loader-spinner'
import Skeleton from 'react-loading-skeleton'
import { Helmet } from 'react-helmet'

const Shop = props => {
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [urlParams, setUrlParams] = useState(null)
    const [showCategories, setShowCategories] = useState(false)
    const [listings, setListings] = useState([])

    useEffect(() => {
        if (urlParams !== util.getUrlParams()) {
            setUrlParams(util.getUrlParams())
        }
    }, [props.location])

    useEffect(() => {
        if (urlParams) {

            const type = urlParams.type
            const category = urlParams.category
            const tempValues = [...Array(12).keys()].map(val => ({ SubCategory: '', CoverImage: '', Price: 0, Title: '', id: val }))
            if (!category) {
                props.history.push('/')
                return
            }
            if (!type) {
                setShowCategories(true)
                setListings(Object.entries({ '': tempValues, ' ': tempValues }))
            } else {
                setShowCategories(false)
                setListings(Object.entries({ '': tempValues }))
            }
            if (!loading) {
                props.actions.getListings()
            }
            setLoading(true)

        }

    }, [urlParams])

    useEffect(() => {
        if (props.listings.err) {
            setError(props.listings.err)
            setLoading(false)
            return
        }

        if (props.listings.data) {
            if (Object.keys(props.listings.data).length > 0) {
                if (listings !== props.listings.data) {
                    setListings(Object.entries(props.listings.data))
                    setLoading(false)
                }
            } else {
                props.history.push('/')
            }
        }

    }, [props.listings])

    if (error) {
        setTimeout(() => { setError(false) }, 14000)

    }

    const navigateToListing = (name, Title, item, id) => {
        if (name !== 'add-bag' && id.length > 3) {
            props.actions.SetViewListing(item);
            props.history.push(`/view/${Title.trim().replace(/\s/g, '-')}?id=${id}`)
        }
    }

    return (
        <Background id={'background'}>
            <Helmet>
                <meta name="description" content={`Shop for ${util.getUrlParams().type ? `${util.getUrlParams().type}` : `${util.getUrlParams().category} `} on Art Home`} />
                <meta itemprop="description" content={`Shop for ${util.getUrlParams().type ? `${util.getUrlParams().type}` : `${util.getUrlParams().category} `} on Art Home`} />
                <meta property="og:description" content={`Shop for ${util.getUrlParams().type ? `${util.getUrlParams().type}` : `${util.getUrlParams().category} `} on Art Home`} />
                <meta name="twitter:description" content={`Shop for ${util.getUrlParams().type ? `${util.getUrlParams().type}` : `${util.getUrlParams().category} `} on Art Home`} />
            </Helmet>
            {error !== false && <Alert variant={'info'}> {error}  {/*{link && <a href={link.url} className="alert-link">{link.title} </a>}*/}</Alert>}

            {showCategories ?
                listings.map(([sectionTitle, value]) => <Section key={sectionTitle}>
                    <SpaceBetween>
                        <SectionHeader> {sectionTitle.trim().length > 0 ? sectionTitle : <Skeleton width={120} />}  </SectionHeader>
                        {sectionTitle.trim().length > 0 ? <SectionHeader href={`/shop?category=${urlParams.category}&type=${sectionTitle}`} isAction>  SEE ALL </SectionHeader> : <Skeleton width={100} />}
                    </SpaceBetween>
                    <Container>
                        {value.map(({ SubCategory, Shop, StockRemaining, Shipping, CoverImage, Price, Title, id }, index) =>
                            <Block key={`${sectionTitle}-${index}`} onClick={({ target: { name } }) => { navigateToListing(name, Title, value[index], id) }}>
                                {Title ? <Image src={CoverImage} /> : < StyledSkeleton />}
                                <Titles href={`/view/${Title.trim().replace(/\s/g, '-')}?id=${id}`}> {Title || <Skeleton />} </Titles>
                                <Description > {SubCategory || <Skeleton />} </Description>
                                <Row>
                                    <Pricetag >{Price ? `$${Price.toFixed(2)}` : <Skeleton />} </Pricetag>
                                    {Price ? <AddCart src={AddCartImg} name='add-bag' onClick={() => { props.actions.addItem({ SubCategory, Shipping, Shop, StockRemaining, CoverImage, Price, Title, id }) }} /> : <Skeleton />}
                                </Row>
                            </Block>)}
                    </Container>
                </Section>)
                :
                listings.map(([sectionTitle, value]) => <Section noBorder key={sectionTitle}>
                    <SpaceBetween>
                        <SectionHeader> {sectionTitle.trim().length > 0 ? sectionTitle : <Skeleton width={120} />}  </SectionHeader>
                    </SpaceBetween>
                    <Container dontShowCategories>
                        {value.map(({ SubCategory, Shop, StockRemaining, Shipping, CoverImage, Price, Title, id }, index) =>
                            <Block key={`${sectionTitle}-${index}`} onClick={({ target: { name } }) => navigateToListing(name, Title, value[index], id)}>
                                {Title ? <Image src={CoverImage} /> : < StyledSkeleton />}
                                <Titles href={`/view/${Title.trim().replace(/\s/g, '-')}?id=${id}`}> {Title || <Skeleton />} </Titles>
                                <Description > {SubCategory || <Skeleton />} </Description>
                                <Row>
                                    <Pricetag >{Price ? `$${Price.toFixed(2)}` : <Skeleton />} </Pricetag>
                                    {Price ? <AddCart src={AddCartImg} name='add-bag' onClick={() => { props.actions.addItem({ SubCategory, Shop, Shipping, StockRemaining, CoverImage, Price, Title, id }) }} /> : <Skeleton />}
                                </Row>
                            </Block>)}
                    </Container>
                </Section>)
            }
        </Background >
    )

}


const mapStateToProps = state => ({
    app: state.App,
    listings: state.Listings
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
})

export default connect(mapStateToProps,
    mapDispatchToProps)(Shop)