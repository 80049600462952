import React, { useEffect, useState } from 'react'
import { Wrapper, Button, InputField } from './styles'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Actions from '../../redux/actions'
import firebase from 'firebase'

const Quantity = props => {
    const { value, setValue, maxValue } = props
    const [qty, setQty] = useState(value)

    useEffect(() => {
        setValue(qty)
    }, [qty])

    return (
        <Wrapper>
            <Button name={'quantity'} decrement onClick={() => { qty > 1 && setQty(qty - 1) }}> -</Button>
            <InputField value={value} onChange={({ target: value }) => setQty((parseInt(value) <= maxValue && parseInt(value)) || 1)} />
            <Button name={'quantity'} onClick={() => { qty < maxValue && setQty(qty + 1) }}> +</Button>
        </Wrapper >
    )

}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)

})

const mapStateToProps = state => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(Quantity)