import styled, { css } from 'styled-components'
import { device } from '../../utils/device'
// NINA

export const Background = styled.div`
    margin-bottom: 20px;
    overflow: auto;
    margin-top: 90px;
    @media ${device.mobile}{
        margin-top: 80px;
    }
`
export const InputField = styled.input`
  margin-bottom: 5px;
  padding:10px;
  font-family: Play, sans-serif;
  border: 0;
  width: 350px;
  align-self: center;
  border-radius: 10px;
  ${props => !props.readOnly && css`
    box-shadow: 0 0 15px 4px rgba(0,0,0,0.07);
    background: rgba(0,0,0,0.02);
  `}
  @media ${device.mobile}{
    width: 80%;
}
`

export const TextArea = styled.textarea`
  margin-bottom: 5px;
  padding: 10px;
  font-family: Play, sans-serif;
  border: 0;
  align-self: center;
  border-radius: 10px;
  width: 350px;
  height: 120px;
  ${props => !props.readOnly && css`
    box-shadow: 0 0 15px 4px rgba(0,0,0,0.07);
    background: rgba(0,0,0,0.02);
  `}
  @media ${device.mobile}{
    width: 80%;
}
`

export const SelectWrapper = styled.div`
    width: 350px;
    @media ${device.mobile}{
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

`

export const Row = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
    @media ${device.mobile}{
        flex-direction: column;
    }
`

export const Label = styled.label`
    display: flex;
    width: 200px;
    font-family: Play, sans-serif;
    align-self: center;
    @media ${device.mobile}{
        justify-content: center;
    }
    ${props => props.receive && css`
        width: max-content;
    `}
`

export const Submit = styled.button`
    background: #05386b;
    border: none;
    color: #edf5e1;
    padding 8px;
    font-family: Play, sans-serif;
    border-radius: 4px;
    ${props => props.secondary && css`
        margin-left: 40px;
        background: white;
        border: 1px solid #05386b;
        color: #05386b;;
    `}

    @media ${device.mobile} {
        margin: 10px;
    }
    align-self: center;
    ${props => props.disabled && css`
        border: 1px solid grey;
        color: white;
        background: grey;
    `}
`

export const ThumbNails = styled.div`
    margin-top: 20px;
    display: flex;
    @media ${device.mobile} {
        margin-left: 20px;
    }
`

export const Image = styled.img`
  ${props => props.thumb && css`
    width: 100px;
    height: 100px;
    margin-right: 20px;
    border-radius: 4px;
    @media ${device.mobile} {
      width: 60px;
      height: 60px;
  }
  `}
`

export const ThumbImageDel = styled.img`
    width: 20px;
    margin-left: auto;
    margin-right: 20px;
`
