import styled, { css, keyframes } from 'styled-components'
import { device } from '../../utils/device'


export const HeaderContainer = styled.div`
    background: white;
    padding-left: 20px;
    padding-right: 20px;
    // position: fixed;
    // width: 100%;
    display: flex;
    position: fixed;
    width: 100%;
    height: 100px;
    top: 0;
    left: 0;
    z-index: 5000;
    flex-direction: row;
    @media ${device.desktop}{
        padding-left: 40px;
        padding-right: 40px;
    }
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
`
const fadeInUp = keyframes` 
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
    }
  
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  `
const slideLeft = keyframes` 
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
  `

const slideRight = keyframes` 
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
  `



export const MenuLabels = styled.a`
    color: #05386b;
    font-size: 18px;
    margin-top: auto;
    margin-bottom: auto;

    font-family: 'Play', sans-serif;
    text-transform: uppercase;
    :hover{
        cursor: pointer;
    }

    ${props => props.dropdown && css`
        padding: 12px;
        // border-bottom: 1px solid grey;
    `}

    // animation-name: ${fadeInUp};
    // animation-duration: 2s;
`


export const SideMenuLabels = styled.a`
    display: flex;
    color: #05386b;
    font-size: 21px;
    margin-top: auto;
    margin-bottom: auto;
    font-family: 'Play', sans-serif;
    margin-left: 10px;
    padding: 4px;
    ${props => props.menuIcon && css`
        justify-content: flex-end;
        display: flex;
        margin-left: 0;
        padding: 6px;
    `}

    ${props => props.subMenu && css`
        font-size: 18px;
    `}

`

export const MenuWrapper = styled.div`
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    @media ${device.mobile}{
        display: none;
    }
`

export const SideMenu = styled.div`
    position: absolute;
    background: white;
    height: 100vh;
    width: 50%;
    overflow: scroll;
    transition: 1s;  
    max-width: 220px;  
    left: ${props => props.showSidebar ? '0px' : '-55%'};

    @media ${device.tablet}, ${device.desktop} {
        display: none;
    }

    ::-webkit-scrollbar {
        display: none;
      }
      
      /* Hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
      
`

export const Logo = styled.img`
    width: 140px;
    height: 140px;
    margin-right: 20px;
    margin: auto;
    :hover{
        cursor: pointer;
    }
    @media ${device.mobile} {
        width: 100px;
        margin-left: 20px;
    }
`
export const MenuIcon = styled.img`
    width: 20px;
    margin-right: 10px;
    @media ${device.tablet}, ${device.desktop} {
        display: none;
    }
`
export const Cart = styled.img`
    width: 30px;
    margin-left: auto;
    ${props => props.shop && css`
        margin-right: 20px;    
    `}
    @media ${device.mobile} {
        width: 25px;
    }
    ${props => props.shake && css`
        animation: shake 0.5s;
        @keyframes shake {
            0% { transform: translate(1px, 1px) rotate(0deg); }
            10% { transform: translate(-1px, -2px) rotate(-1deg); }
            20% { transform: translate(-3px, 0px) rotate(1deg); }
            30% { transform: translate(3px, 2px) rotate(0deg); }
            40% { transform: translate(1px, -1px) rotate(1deg); }
            50% { transform: translate(-1px, 2px) rotate(-1deg); }
            60% { transform: translate(-3px, 1px) rotate(0deg); }
            70% { transform: translate(3px, 1px) rotate(-1deg); }
            80% { transform: translate(-1px, -1px) rotate(1deg); }
            90% { transform: translate(1px, 2px) rotate(0deg); }
            100% { transform: translate(1px, -2px) rotate(-1deg); }
        }
    `}
`

export const CartCount = styled.label`
    text-align: center;
    font-family: 'Play', sans-serif;
    margin-bottom: 0;
    margin-top: auto;
`

export const CartContainer = styled.div`
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    :hover{
        cursor: pointer;
    }
`
export const Ul = styled.ul`
    list-style: none;
    padding: 0;
    display: ${props => (props.show ? "flex" : "none")};
    min-width: 150px;
    position: absolute;
    margin-left: -25px;
    z-index: 1000;
    flex-direction: column;
    background-color: white;
    // margin-top: 22px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5)
`