import styled, { createGlobalStyle, css } from 'styled-components'
import { device } from '../../utils/device'

export const Submit = styled.button`
    background: #05386b;
    border: none;
    color: #edf5e1;
    padding 8px;
    margin-top: 20px;
    font-family: Play, sans-serif;
    border-radius: 4px;
    ${props => props.secondary && css`
        margin-left: 10px;
        background: white;
        border: 1px solid #05386b;
        color: #05386b;;
    `}
    @media ${device.mobile} {
        margin: 10px;
        padding 6px;
    }
    align-self: center;
    ${props => props.disabled && css`
        background: grey;
        border: grey;
    `}
`


export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: ${props => !props.noMarginTop && '20px'};
`

export const GlobalStyle= createGlobalStyle`
  .StripeElement {
    box-sizing: border-box;
    height: 40px;
    padding: 10px 12px;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: white;
    width: 300px;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
  }
  
  .StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
  }
  
  .StripeElement--invalid {
    border-color: #fa755a;
  }
  
  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }
  
  .card-errors{
    text-align: center;
    color: #fa755a;
  }  

`