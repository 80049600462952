import React, { useEffect, useState } from 'react'
import {
    Background, InputField, Row, Label, TextArea, Submit, ThumbNails, Image, ThumbImageDel, SelectWrapper
} from './styles'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Actions from '../../redux/actions'
import DeleteImage from '../../assets/remove.svg'
import util from '../../utils/common'
import ImageUploader from 'react-images-upload'
import Alert from 'react-bootstrap/Alert'
import firebase from '../../firebase'
import Select from 'react-select';
// import Resizer from 'react-image-file-resizer';

import loadingGif from '../../assets/loading.gif'

var storageRef = firebase.storage().ref();
var formData = new FormData()

const AddListing = props => {
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [dropdown, setDropDown] = useState(false)
    const [urlParams, setUrlParams] = useState(util.getUrlParams())
    const [mode, setMode] = useState('add')
    const [product, setProduct] = useState({ Title: '', Description: '', Price: 10, Quantity: 1, Category: '', Shipping: 0, SubCategory: '' })

    useEffect(() => {
        if (urlParams) {
            if (urlParams.mode === 'edit') {
                if (!urlParams.id) {
                    props.history.goBack()
                    return
                }
                setMode('edit')
            }
        }
    }, [props.history.location])

    useEffect(() => {
        if (!props.shop.products) {
            return
        }
        if (props.shop.products.err) {
            setLoading(false)
            window.scrollTo(0, 0)
            setError(props.shop.err)
            return
        }
        if (props.shop.products.Listings) {
            if (mode === 'edit') {
                setLoading(false)
                recdProductInAPI()
            }
        }
    }, [props.shop.products])

    useEffect(() => {
        if (!props.shop.addedProducts) {
            return
        }
        if (props.shop.addedProducts.err) {
            setLoading(false)
            setError(props.shop.addedProducts.err)
            window.scrollTo(0, 0)
            return
        }
        if (props.shop.addedProducts.data) {
            setLoading(false)
            props.actions.ClearAddShopListings()
            props.actions.GetShopListings({})
            props.history.push(`/view?id=${props.shop.addedProducts.data}`)

        }
    }, [props.shop.addedProducts])

    useEffect(() => {
        if (mode === 'edit') {
            if (!util.getResponseKey(['shop', 'products', 'Listings'], props)) {
                setLoading(true)
                firebase.auth().onAuthStateChanged(_ => {
                    firebase.auth().currentUser ? firebase.auth().currentUser.getIdToken().then(token => {
                        props.actions.GetShopListings({})
                    }) : props.history.push('/login')
                })

            } else {
                recdProductInAPI()
            }
        } else {
            setDropDown({})
        }
    }, [mode])


    const recdProductInAPI = () => {
        const prod = props.shop.products.Listings.find(item => item.id === urlParams.id)
        setDropDown({ value: prod.SubCategory, label: prod.SubCategory, main: prod.Category })
        setProduct(props.shop.products.Listings.find(item => item.id === urlParams.id))
    }

    const checkFormDataEmpty = () => formData.getAll("images[]").length === 0

    const checkValidListing = async () => {

        if (!product.Title || !product.Description || !product.Price || !product.Quantity || (!product.Shipping && product.Shipping !== 0)) {
            setError('Please provide all the details')
            window.scrollTo(0, 0)
            return
        }
        if (!product.Category || !product.SubCategory) {
            setError('Please provide Category and Subcategory')
            window.scrollTo(0, 0)
            return
        }

        formData.append('Images', JSON.stringify(product.Images || []))
        if ((!product.Images || product.Images.length === 0) && checkFormDataEmpty()) {
            setError('Please provide atleast one image of your product')
            window.scrollTo(0, 0)
            return
        }

        const uriImages = product.Images ? product.Images.length : 0
        const formImages = formData.getAll('images[]') ? formData.getAll('images[]').length : 0


        if ((uriImages + formImages) > 4) {
            setError('Only 4 images can be provided.')
            window.scrollTo(0, 0)
            return
        }

        formData.append('Title', product.Title)
        formData.append('Description', product.Description)
        formData.append('Quantity', product.Quantity)
        formData.append('Shipping', product.Shipping)
        formData.append('Price', product.Price)
        formData.append('Category', product.Category)
        formData.append('SubCategory', product.SubCategory)
        if (product.id) {
            formData.append('id', product.id)
        }

        const idToken = firebase.auth().currentUser && await firebase.auth().currentUser.getIdToken()
        formData.append('idToken', idToken)

        props.actions.AddShopListings(formData)
        setLoading(true)
    }

    if (error) {
        setTimeout(() => { setError(false) }, 4000)
    }


    const handleUpload = picture => {
        formData = new FormData()
        var index = 0, imageUrls = product.FormImages || []

        // setMakingFileUpload(true)
        for (var file of picture) {
            if (file) {
                var uploadTask = storageRef.child(`products/${new Date().getTime()}`).put(file)
                uploadTask.on('state_changed',
                    (snapshot) => {
                        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                        console.log('Upload is ' + progress + '% done')
                        switch (snapshot.state) {
                            case firebase.storage.TaskState.PAUSED: // or 'paused'
                                break;
                            case firebase.storage.TaskState.RUNNING: // or 'running'
                                break;
                        }
                    },
                    (error) => {
                        // Handle unsuccessful uploads
                    },
                    () => {
                        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                            index += 1
                            imageUrls.push(downloadURL)
                            console.log(downloadURL, index)
                            formData.append('images[]', downloadURL, index)
                            if (index === picture.length) {
                                setProduct({ ...product, FormImages: imageUrls })
                            }
                        })
                    })

            } else {
                setProduct({ ...product, Images: [], CoverImage: '' })
            }
        }
    }

    if (mode === 'edit') {
        if (!product.Title) {
            return (
                <div> Loading</div>
            )
        }
    }

    const groupedOptions = [
        {
            label: 'Accessories',
            options: [
                { value: 'Earrings', label: 'Earrings', main: 'Accessories' },
                { value: 'Necklace', label: 'Necklace', main: 'Accessories' },
                { value: 'Bracelet', label: 'Bracelet', main: 'Accessories' },
                { value: 'Headbands', label: 'Headbands', main: 'Accessories' },

            ]
        },
        {
            label: 'Paintings',
            options: [
                { label: 'Charcoal', value: 'Charcoal', main: 'Paintings', },
                { label: 'Oil Color', value: 'OilColor', main: 'Paintings' },
                { label: 'Water Color', value: 'WaterColor', main: 'Paintings' },
                { label: 'Color Pencil', value: 'ColorPencil', main: 'Paintings' }]
        },

    ]

    const removeLinksAndHTML = str => {
        var tmp = document.createElement("DIV");
        tmp.innerHTML = str;
        const truncated = tmp.textContent || tmp.innerText || "";
        return truncated.replace(/\b(?:(?:https?|ftp|http):\/\/|www\.)[-a-z0-9+&@#\/%?=~_|!:,.;]*[-a-z0-9+&@#\/%=~_|]/i, '').replace(/\b(\w)+\@(\w)+\.(\w)+\b/g, '')
    }

    const removeImage = ind => {
        if (product.Images) {
            if (ind > product.Images.length - 1) {
                var actualIndex = ind - (product.Images.length)
                removeImageofIndex(actualIndex)
            } else {
                setProduct({ ...product, Images: product.Images.filter((_, index) => index !== ind) })
            }
        } else {
            removeImageofIndex(ind)
        }
    }

    const removeImageofIndex = ind => {
        const newImages = formData.getAll('images[]').filter((_, index) => index !== ind)
        formData.set("images[]", newImages)
        setProduct({ ...product, FormImages: product.FormImages.filter((_, index) => index !== ind) })
    }

    const handleCategoryChange = item => {
        const { value, main } = item
        setDropDown(item)
        setProduct({ ...product, Category: main, SubCategory: value })
    }

    if (loading) {

        return (<img src={loadingGif} style={{ display: 'flex', marginLeft: 'auto', marginRight: 'auto', marginTop: '100px', width: '400px', height: '300px' }} />)
    }

    return (
        <Background id={'background'}>
            <Row >
                {error !== false && <Alert variant={'info'}> {error} </Alert>}
            </Row >
            <Row >
                <Label> Title:</Label>
                <InputField onChange={({ target: { value } }) => setProduct({ ...product, Title: removeLinksAndHTML(value) })} value={product.Title} />
            </Row>
            <Row >
                <Label> Description:</Label>
                <TextArea onChange={({ target: { value } }) => setProduct({ ...product, Description: removeLinksAndHTML(value) })} value={product.Description} />
            </Row>
            <Row >
                <Label> Cateogry:</Label>
                <SelectWrapper>
                    <Select value={dropdown} options={groupedOptions} onChange={handleCategoryChange} formatGroupLabel={data => (<span>{data.label}</span>)} />
                </SelectWrapper>
            </Row>

            <Row >
                <Label> Price:</Label>
                <InputField inputMode='decimal' type='number' min={0.5} onBlur={() => { setProduct({ ...product, Price: parseFloat(product.Price.toFixed(2)) }) }}
                    onChange={({ target: { value } }) => { value[value.length - 1] === '.' ? setProduct({ ...product, Price: parseFloat(value) }) : setProduct({ ...product, Price: parseFloat(value) || 0 }) }} value={product.Price} />
            </Row>
            <Row >
                <Label> Shipping:</Label>
                <InputField inputMode='decimal' type='number' min={0.5} onBlur={() => { setProduct({ ...product, Shipping: parseFloat(product.Shipping.toFixed(2)) }) }}
                    onChange={({ target: { value } }) => { value[value.length - 1] === '.' ? setProduct({ ...product, Shipping: parseFloat(value) }) : setProduct({ ...product, Shipping: parseFloat(value) || 0 }) }} value={product.Shipping} />
            </Row>
            <Row>
                <Label receive>You Receive: ${((parseFloat(product.Price) + parseFloat(product.Shipping)) * 0.9).toFixed(2)}</Label>
            </Row>

            <Row >
                <Label> Quantity:</Label>
                <InputField inputMode='decimal' type='number' min={1}
                    onChange={({ target: { value } }) => setProduct({ ...product, Quantity: parseInt(value) || 0 })} onBlur={() => !(product.Quantity >= 1) && setProduct({ ...product, Quantity: 1 })} value={product.Quantity} />
            </Row>

            <ImageUploader
                style={{ width: '250px', marginLeft: 'auto', marginRight: 'auto' }}
                withIcon={false}
                buttonText='Add Images'
                onChange={handleUpload}
                label={`Max 4 images. \n Accepted filetypes: .jpg/jpeg, .svg, .png, .bmp`}
                imgExtension={['.jpg', '.jpeg', '.svg', '.png', '.bmp']}
                maxFileSize={72242880}
            />
            <Row>
                <ThumbNails>
                    {[...product.Images || [], ...product.FormImages || []].map((item, ind) =>
                        <div key={ind} style={{ display: 'flex', flexDirection: 'column' }} onClick={() => removeImage(ind)} >
                            <ThumbImageDel src={DeleteImage} />
                            <Image thumb src={item} />
                        </div>

                    )}
                </ThumbNails>
            </Row>
            <Row >
                <Submit disabled={!product.Title || !product.Description || !product.Price || !product.Quantity} onClick={checkValidListing}>
                    {mode !== 'edit' ? 'Create Listing' : 'Update Listing'}
                </Submit>
                <Submit secondary onClick={() => props.history.goBack()}>
                    Cancel
            </Submit>
            </Row >
        </Background >
    )

}


const mapStateToProps = state => ({
    app: state.App,
    listings: state.Listings,
    shop: state.Shop
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
})

export default connect(mapStateToProps,
    mapDispatchToProps)(AddListing)