import axios from 'axios'
import util from '../../utils/common'
import firebase from '../../firebase'

const url = 'https://australia-southeast1-ecommerce-web-91811.cloudfunctions.net/'
// const url = 'http://localhost:5001/ecommerce-web-91811/australia-southeast1/'

const instance = axios.create({
    timeout: 1200000,
    baseURL: url,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }
})

instance.interceptors.request.use(
    config => {
        //   store.dispatch(Actions.BlockUI(true))
        //   ApiConstants.blockCount = ApiConstants.blockCount + 1
        //   if (ApiConstants.token) {
        //     config.headers.Authorization = `Bearer ${ApiConstants.token}`
        //   }
        return config
    },
    error => {
        //   disableBlockUI()
        const errorMessage = 'Network error Occured. Are you offline?'
        return { status: 500, data: null, err: errorMessage }
    }
)

instance.interceptors.response.use(
    config => {
        //   disableBlockUI()
        return { status: config.status, data: config.data }
    },
    error => {
        //   disableBlockUI()
        console.log('Axios error in response', error.response)
        if (!error.status && !error.response) {
            return { status: 500, err: 'Network error Occured. Are you offline?', data: null }
        }
        const { status, data, err } = error.response
        // TODO: Change to session expired or invalid login
        if (status === 401) {
            // Reload Page to force protected route to store current Location
            // ApiConstants.token = ''
            // sessionStorage.removeItem('token')
            // window.location.reload()
        }
        //   const errorMessage = data.errorMessage ? data.errorMessage[0] ? data.errorMessage[0] : data.errorMessage : 'Error Occured'
        return { status, ...error.response }
        // Handle error
    }
)



const fetchListings = (data) => {
    // TODO: Use data for price sort
    const params = util.getUrlParams(); var searchString = ''
    searchString = searchString + (params.category ? `&category=${params.category}` : '')
    searchString = searchString + (params.type ? `&subCategory=${params.type}` : '')

    return instance.get('getListings?' + searchString)
        .then(response => {
            return response.data
        })
        .catch(error => {
            console.log(error)
        })
}

const postApi = async (url, data) => {
    const idToken = firebase.auth().currentUser && await firebase.auth().currentUser.getIdToken()
    return instance.post(url, { ...data, idToken })
        .then(response => {
            return response.data
        })
        .catch(error => {
            console.log(error)
        })
}

const getUser = () => {
    return postApi('getUser', {})
        .then(response => {
            return response
        })
        .catch(error => {
            console.log(error)
        })
}

const addListing = (data) => {
    return postApi('addListing', data)
        .then(response => {
            return response
        })
        .catch(error => {
            console.log(error)
        })
}

const signupUser = (data) => {
    return postApi('signUpUser', data)
        .then(response => {
            return response
        })
        .catch(error => {
            console.log(error)
        })
}
const updateUser = (data) => {
    return postApi('updateProfile', data)
        .then(response => {
            return response
        })
        .catch(error => {
            console.log(error)
        })
}
const addShop = (data) => {
    return postApi('addShops', data)
        .then(response => {
            return response
        })
        .catch(error => {
            console.log(error)
        })
}

const updateShopLogo = (data) => {
    return postApi('updateShopLogo', data)
        .then(response => {
            return response
        })
        .catch(error => {
            console.log(error)
        })
}

const getShopListings = (data) => {
    return postApi('getShopListings', data)
        .then(response => {
            return response
        })
        .catch(error => {
            console.log(error)
        })
}

const putShopListings = (data) => {
    return axios.post(`${url}addListing`, data, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'multipart/form-data',
        }
    })
        .then(response => {
            return response.data
        })
        .catch(error => {
            console.log(error)
        })
}

const fetchListingsId = (data) => {
    return postApi('getListingById', data)
        .then(response => {
            return response
        })
        .catch(error => {
            console.log(error)
        })
}

const verifyCartPrice = (data) => {
    return postApi('verifyCartPrice', data)
        .then(response => {
            return response
        })
        .catch(error => {
            console.log(error)
        })
}

const checkout = (data) => {
    return postApi('checkout', data)
        .then(response => {
            return response
        })
        .catch(error => {
            console.log(error)
        })
}

const checkoutSuccess = (data) => {
    return postApi('checkoutSuccess', data)
        .then(response => {
            return response
        })
        .catch(error => {
            console.log(error)
        })
}

const checkoutFailed = (data) => {
    return postApi('checkoutFailed', data)
        .then(response => {
            return response
        })
        .catch(error => {
            console.log(error)
        })
}

const autofillAddress = (data) => {
    return postApi('autofillAddress', data)
        .then(response => {
            return response
        })
        .catch(error => {
            console.log(error)
        })
}

const provideShippingInfo = (data) => {
    return postApi('provideOrderShippingInfo', data)
        .then(response => {
            return response
        })
        .catch(error => {
            console.log(error)
        })
}

const getPurchases = (data) => {
    return postApi('listPurchases', data)
        .then(response => {
            return response
        })
        .catch(error => {
            console.log(error)
        })
}

const leaveReview = (data) => {
    return postApi('leaveReview', data)
        .then(response => {
            return response
        })
        .catch(error => {
            console.log(error)
        })
}

const addCard = (data) => {
    return postApi('addCard', data)
        .then(response => {
            return response
        })
        .catch(error => {
            console.log(error)
        })
}

const createStripeAccount = (data) => {
    return postApi('createStripeAccount', data)
        .then(response => {
            return response
        })
        .catch(error => {
            console.log(error)
        })
}

const retrieveStripeAccount = (data) => {
    return postApi('retrieveStripeAccount', data)
        .then(response => {
            return response
        })
        .catch(error => {
            console.log(error)
        })
}

const Api = {
    fetchListings,
    getUser,
    addListing,
    signupUser,
    updateUser,
    addShop,
    updateShopLogo,
    getShopListings,
    putShopListings,
    fetchListingsId,
    verifyCartPrice,
    checkout,
    checkoutSuccess,
    checkoutFailed,
    autofillAddress,
    provideShippingInfo,
    getPurchases,
    leaveReview,
    addCard,
    createStripeAccount,
    retrieveStripeAccount
}

export default Api