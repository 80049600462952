export const STORE_CLIENTS = 'STORE_CLIENTS'
export const SIDE_MENU_OPEN = 'SIDE_MENU_OPEN'

export const GET_LISTINGS = 'GET_LISTINGS'
export const PUT_LISTINGS = 'PUT_LISTINGS'
export const PUT_LISTINGS_ERROR = 'PUT_LISTINGS_ERROR'

export const GET_LISTINGS_ID = 'GET_LISTINGS_ID'
// export const PUT_LISTINGS_ID = 'PUT_LISTINGS_ID'

export const GET_USER = 'GET_USER'
export const PUT_USER = 'PUT_USER'
export const PUT_USER_ERROR = 'PUT_USER_ERROR'

export const SIGNUP_USER = 'SIGNUP_USER'
export const PUT_SIGNUP_USER = 'PUT_SIGNUP_USER'
export const PUT_SIGNUP_USER_ERROR = 'PUT_SIGNUP_USER_ERROR'

export const UPDATE_USER = 'UPDATE_USER'
export const PUT_UPDATE_USER = 'PUT_UPDATE_USER'

export const ADD_ITEM_CART = 'ADD_ITEM_CART'
export const UPDATE_ITEMS_CART = 'UPDATE_ITEMS_CART'
export const REMOVE_ITEM_CART = 'REMOVE_ITEM_CART'


export const VERIFY_CART_ITEMS_PRICE = 'VERIFY_CART_ITEMS_PRICE'
export const PUT_VERIFY_CART_ITEMS_PRICE = 'PUT_VERIFY_CART_ITEMS_PRICE'

export const ADD_SHOP = 'ADD_SHOP'
export const PUT_ADD_SHOP = 'PUT_ADD_SHOP'

export const GET_SHOP_LISTINGS = 'GET_SHOP_LISTINGS'
export const PUT_SHOP_LISTINGS = 'PUT_SHOP_LISTINGS'

export const ADD_SHOP_LISTINGS = 'ADD_SHOP_LISTINGS'
export const PUT_ADD_SHOP_LISTINGS = 'PUT_ADD_SHOP_LISTINGS'

export const PUT_VIEW_LISTING = 'PUT_VIEW_LISTING'
export const CLEAR_VIEW_LISTING = 'CLEAR_VIEW_LISTING'

export const REDIRECT_AFTER_LOGIN = 'REDIRECT_AFTER_LOGIN'
export const CLEAR_REDIRECT_AFTER_LOGIN = 'CLEAR_REDIRECT_AFTER_LOGIN'

export const CHECKOUT = 'CHECKOUT'
export const PUT_CHECKOUT = 'PUT_CHECKOUT'

export const CLEAR_CHECKOUT_STRIPE_ID = 'CLEAR_CHECKOUT_STRIPE_ID'

export const VERIFY_CHECKOUT_SUCCESS = 'VERIFY_CHECKOUT_SUCCESS'
export const PUT_VERIFY_CHECKOUT_SUCCESS = 'PUT_VERIFY_CHECKOUT_SUCCESS'

export const VERIFY_CHECKOUT_FAILED = 'VERIFY_CHECKOUT_FAILED'
export const PUT_VERIFY_CHECKOUT_FAILED = 'PUT_VERIFY_CHECKOUT_FAILED'


export const PROVIDE_ORDER_SHIPPING_INFO = 'PROVIDE_ORDER_SHIPPING_INFO'
export const PUT_ORDER_SHIPPING_INFO = 'PUT_ORDER_SHIPPING_INFO'

export const GET_PURCHASES = 'GET_PURCHASES'
export const PUT_GET_PURCHASES = 'PUT_GET_PURCHASES'

export const LEAVE_REVIEW = 'LEAVE_REVIEW'
export const PUT_LEAVE_REVIEW = 'PUT_LEAVE_REVIEW'

export const ADD_CARD = 'ADD_CARD'
export const PUT_ADD_CARD = 'PUT_ADD_CARD'

export const CLEAR_ADD_CARD = 'CLEAR_ADD_CARD'


export const CREATE_STRIPE_ACCOUNT = 'CREATE_STRIPE_ACCOUNT'
export const PUT_CREATE_STRIPE_ACCOUNT = 'PUT_CREATE_STRIPE_ACCOUNT'

export const RET_STRIPE_ACCOUNT = 'RET_STRIPE_ACCOUNT'
export const PUT_RET_STRIPE_ACCOUNT = 'PUT_RET_STRIPE_ACCOUNT'
