import { call, put, all, takeEvery, fork, takeLatest, select } from 'redux-saga/effects'
import * as Actions from '../actions/action_types'
import Api from '../api'
import util from '../../utils/common'
import listingsSaga from './listingsSaga'
import appSaga from './appSaga'
import shopSaga from './shopSaga'


export default function* mySaga() {
  yield fork (listingsSaga)
  yield fork (appSaga)
  yield fork (shopSaga)
}