import * as actionType from '../actions/action_types'
const DEFAULT_STATE = { items: [] }

export const Cart = (state = DEFAULT_STATE, action) => {
    const { data, err, payload } = action
    switch (action.type) {
        case actionType.UPDATE_ITEMS_CART:
            return Object.assign({}, state, {
                items: payload
            })

        case actionType.PUT_VERIFY_CART_ITEMS_PRICE:
            return Object.assign({}, state, {
                items: data,
                err
            })
        case actionType.ADD_ITEM_CART:
            try {
                const existsCartItem = state.items.findIndex(item => item.id === payload.id)
                var newItems = [...state.items, { ...payload, qty: payload.qty ? payload.qty : 1 }]
                if (existsCartItem >= 0) {
                    if (newItems[existsCartItem].StockRemaining >= (payload.qty ? payload.qty : newItems[existsCartItem].qty + 1)) {
                        newItems = [...state.items]
                        newItems[existsCartItem].qty = payload.qty ? payload.qty : newItems[existsCartItem].qty + 1
                    } else {
                        return state
                    }
                }
                return Object.assign({}, state, {
                    items: newItems
                })

            } catch (e) {
                return Object.assign({}, state, {
                    items: []
                })
            }
        case actionType.REDIRECT_AFTER_LOGIN:
            return Object.assign({}, state, {
                backUrl: data
            })
        case actionType.CLEAR_REDIRECT_AFTER_LOGIN:
            return Object.assign({}, state, {
                backUrl: data
            })

        case actionType.PUT_CHECKOUT:
            return Object.assign({}, state, {
                stripeSession: data
            })
        // Also Clears cart Items
        case actionType.CLEAR_CHECKOUT_STRIPE_ID:
            return Object.assign({}, state, {
                stripeSession: null,
                items: data ? [] : state.items.length > 0 ? state.items : [],
                err: data ? null : 'Payment was cancelled.',
            })
        default:
            return state
    }
}