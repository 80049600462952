import styled, { css, keyframes } from 'styled-components'
import { device } from '../../utils/device'


export const Wrapper = styled.div`
    display: flex;
    height: 60px;
`
const fadeInUp = keyframes` 
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
    }
  
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  `
const fadeInDown = keyframes` 
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
    }
  
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  `

export const Button = styled.button`
    border: none;
    padding 8px;
    font-family: Play, sans-serif;
    // margin-left: 10px;
    background: white;
    border: 1px solid #05386b;
    color: #05386b;
    align-self: center;

    ${props => props.decrement && css`
      margin-left: 0;
      // margin-right: 10px;
    `}
`

export const InputField = styled.input`
    padding: 8px;
    text-align: center;
    align-self: center;
    border: 0;
    border-top: 1px solid #05386b;
    border-bottom: 1px solid #05386b;
    width: 80px;
`