import styled, { css } from 'styled-components'
import { device } from '../../utils/device'
// NINA

export const Background = styled.div`
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-top: ${props => !props.fromCart && '80px'};
`

export const InputField = styled.input`
  margin-bottom: 5px;
  padding:10px;
  border: 0;
  align-self: center;
  border-radius:10px;
  box-shadow: 0 0 15px 4px rgba(0,0,0,0.07);
  background: rgba(0,0,0,0.02);
  width: 60%;
  @media ${device.mobile}{
    width: 80%;
}
`

export const Row = styled.div`
    display: flex;
    width: 50%;
    max-width: 500px;
    justify-content: center;
    align-self: center;
    margin-top: 20px;
    @media ${device.mobile}{
        width: 90%;
        flex-direction: column;
        min-width: 80%;
    }
`

export const Label = styled.label`
    font-family: 'Play', sans-serif;
    color: #6c757d;
    font-size: 18px;
    margin-top: auto;
    margin-bottom: auto;
    width: 30%;
    @media ${device.mobile}{
        width: 70%;
        align-self: center;
        text-align: center;
    }
    ${props => props.fullWidth && css`
        width: 100%;
        text-align: center;
    `}

    ${props => props.link && css`
        :hover{
            cursor: pointer;
        }
        color: #05386b;
        width: auto;
    `}
`

export const Submit = styled.button`
    background: #05386b;
    border: none;
    color: #edf5e1;
    padding 8px;
    border-radius: 4px;
    @media ${device.mobile} {
        margin: 10px;
    }
    align-self: center;
`

export const Titles = styled.label`
    font-family: 'Play', sans-serif;
    color: black;
    font-size: 25px;
    text-align: center;
`