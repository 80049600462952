import styled, { css } from 'styled-components'
import { device } from '../../utils/device'
import Skeleton from 'react-loading-skeleton'

export const Background = styled.div`
    overflow: auto;
    margin-top: 120px;
`

export const Container = styled.div`
    display: flex;
    overflow: auto;
    //   padding-top: 20px;
    //   flex-wrap: wrap;
    ${props => props.dontShowCategories && css`
        flex-wrap: wrap;
    `}
`

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
    width: 100%;
    @media ${device.mobile}{
        // flex-direction: column;
    }
`

export const Block = styled.div`
    width: 200px;
    width: 220px;
    padding: 10px;
    // border-radius: 12px;
    // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin: 20px;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;
    *:hover{
        cursor: pointer;
    }
    :hover{
        cursor: pointer;
    }
    @media ${device.mobile} {
        width: 150px;
        // height: 100%;
        margin: 10px;
        }
`

export const Titles = styled.a`
    font-family: 'Play', sans-serif;
    color: #05386b;
    font-size: 18px;
    text-align: center;
`

export const Description = styled.label`
    font-family: 'Play', sans-serif;
    color: #6c757d;
    font-size: 18px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
`

export const Pricetag = styled.label`
    font-family: 'Play', sans-serif;
    // color: #6c757d;
    font-size: 18px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
`

export const Image = styled.img`
    // height: 100%; 
    width: 200px;
    height: 150px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 12px;
    @media ${device.mobile} {
        width: 150px;
        margin-left: -10px;
        margin-top: -10px;
        border-radius: 10px;
    }
`

export const AddCart = styled.img`
 
`
export const Section = styled.div`
        display: flex;
        flex-direction: column;
        // width: 100vw;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 20px;
        @media ${device.mobile}{
            margin-left: 10px;
            margin-right: 10px;
            margin-top: 10px;
        }
        ${props => !props.noBorder && css`
            // border-bottom: 1px dashed gray;
        `}
`
export const SectionHeader = styled.a`
    font-family: 'Play', sans-serif;
    color: ${props => !props.isAction ? 'black' : '#fb7633'};
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
    :hover{
        cursor: ${props => !props.isAction ? 'normal' : 'pointer'};
        color: ${props => !props.isAction ? 'black' : '#fb7633'};
    }
`
export const SpaceBetween = styled.div`
        display: flex;
        justify-content: space-between;
        margin-left: 20px;
        @media ${device.mobile}{
         margin-left: 10px;
        }
`

export const StyledSkeleton = styled(Skeleton)`
    width: 200px !important;
    height: 150px;
    border-radius: 12px;
    @media ${device.mobile} {
        width: 150px !important;
        border-radius: 10px;
    }
` 