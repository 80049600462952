import * as actionType from './action_types'

export const addItem = (data) => {
    return {
        type: actionType.ADD_ITEM_CART,
        payload: data
    }
}

export const updateCart = (data) => {
    return {
        type: actionType.UPDATE_ITEMS_CART,
        payload: data
    }
}

export const VerifyCartPrices = (data) => {
    return {
        type: actionType.VERIFY_CART_ITEMS_PRICE,
        payload: data
    }
}

export const setRedirectUrl = (data) => {
    return {
        type: actionType.REDIRECT_AFTER_LOGIN,
        data
    }
}

export const clearRedirectUrl = () => {
    return {
        type: actionType.CLEAR_REDIRECT_AFTER_LOGIN,
        data: null
    }
}

export const checkout = (data) => {
    return {
        type: actionType.CHECKOUT,
        payload: data
    }
}

export const clearStripeSession = (data) => {
    return {
        type: actionType.CLEAR_CHECKOUT_STRIPE_ID,
        data
    }
}


export const checkoutSuccess = (data) => {
    return {
        type: actionType.VERIFY_CHECKOUT_SUCCESS,
        payload: data
    }
}

export const checkoutFailed = (data) => {
    return {
        type: actionType.VERIFY_CHECKOUT_FAILED,
        payload: data
    }
}