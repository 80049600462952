import styled, { css } from 'styled-components'
import { device } from '../../utils/device'
// NINA

export const Background = styled.div`
    @media ${device.mobile}{
        flex-direction: column;
        margin-top: 90px;
    }
    margin-top: 120px;
    overflow: auto;
    margin-bottom: 20px;
`

export const Menu = styled.a`
    padding: 10px;
    font-family: 'Play', sans-serif;
    color: #05386b;
    font-size: 20px;
    text-decoration: none;
    ${props => props.selected && css`
        // border: 1px solid #DCDCDC;
        border-bottom: 1px solid #05386b;
    `}
    @media ${device.mobile}{
        padding: 8px;
        font-size: 16px;
    }
    :hover{
        text-decoration: none;
        cursor: pointer;
    }
`

export const MenuWrapper = styled.div`
    overflow: scroll;
    width: 100%;
    background: transparent;
    height: auto;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-evenly;
    max-width: 700px;
    border-bottom: 1px solid #dcdcdc;
`
export const Content = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
`

export const Title = styled.label`
    font-family: 'Play', sans-serif;
    color: black;
    font-size: 25px;
    text-align: center;
    justify-content: center;
    display: flex;
    ${props => props.main && css`
        margin-top: 20px;
        font-size: 28px;
        color: #6c757d;
    `}
    @media ${device.mobile}{
        font-size: 21px;
    }
`

export const InputField = styled.input`
  margin-bottom: 5px;
  padding:10px;
  border: 0;
  font-family: Play, sans-serif;
  align-self: center;
  border-radius: 10px;
  width: 300px;
  ${props => !props.readOnly && css`
    box-shadow: 0 0 15px 4px rgba(0,0,0,0.07);
    background: rgba(0,0,0,0.02);
  `}
  @media ${device.mobile}{
  }
`

export const Label = styled.label`
    font-family: 'Play', sans-serif;
    color: #6c757d;
    font-size: 18px;
    margin-top: auto;
    margin-bottom: auto;
    width: 30%;
    width: 150px;
    @media ${device.mobile}{
        width: 70%;
        ${props => props.noFixedWidth && css`
            width: auto;
        `}
        align-self: center;
        text-align: center;
    }
    ${props => props.fullWidth && css`
        width: 100%;
        text-align: center;
    `}
    ${props => props.link && css`
        :hover{
            cursor: pointer;
        }
        color: #05386b;
        width: 120px;
    `}

    ${props => props.address && css`
        flex-direction: row;
        display: flex;
    `}
    ${props => props.right && css`
        margin-left: auto;
    `}
    ${props => props.Title && css`
        color: black;
    `}
`

export const Heading = styled.label`
    font-family: 'Play', sans-serif;
    // color: #6c757d;
    font-size: 18px;
    margin-top: auto;
    margin-bottom: auto;
    // width: 100%;
    text-align: center;
    ${props => props.link && css`
        :hover{
            cursor: pointer;
        }
        color: #05386b;
        width: auto;
        margin-right: 40px;
        @media ${device.mobile}{
            margin-right: 0;
        }
    `}
    ${props => props.right && css`
        margin-left: auto;
    `}
`
export const LabelHeading = styled.label`
    font-family: 'Play', sans-serif;
    // color: #6c757d;
    font-size: 18px;
    margin-top: auto;
    margin-bottom: auto;
    width: 30%;
    width: 150px;
    margin-left: auto;
    margin-right: auto;
    @media ${device.mobile}{
        // width: 100%;
        align-self: center;
        text-align: center;
    }
`

export const LabelDesciption = styled.label`
    font-family: 'Play', sans-serif;
    color: #6c757d;
    font-size: 18px;
    margin-top: auto;
    margin-bottom: auto;
    text-align: ${props => !props.Center ? 'left' : 'center'};
    ${props => props.noMargin && css`
        margin-top: 0;
        margin-bottom: 0;
    `}
    width: ${props => !props.noWidth && '200px'};
    ${props => props.tableHeader && css`
        width: 70px;
    `}
    @media ${device.mobile}{
        align-self: center;
        text-align: center;
        ${props=>props.link && css`
            margin-top: 20px;
        `}
    }
    ${props => props.active && css`
        color: green;
    `}
    ${props => props.inactive && css`
        color: red;
    `}
    ${props => props.Title && css`
        color: black;
    `}
    ${props => props.link && css`
    :hover{
        cursor: pointer;
    }
        color: #05386b;
    `}
`

export const ContentLabel = styled.label`
    font-family: 'Play', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    width: 100%;
    text-align: center;
`

export const Row = styled.div`
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    justify-content: center;
    max-width: 1000px;
    @media ${device.mobile}{
        width: 100%;
    }
    ${props => props.column && css`
        flex-direction: column;
        width: 100%;
        align-items: center;
    `}
    ${props => props.change && css`
        flex-direction: row;
        width: 100%;
        @media ${device.mobile}{
            flex-direction: column;
        }
    `}
    ${props => props.left && css`
        justify-content: left;
        width: 100%;
    `}
    ${props => props.evenly && css`
        justify-content: space-between;
        width: 100%;
    `}
    ${props => props.instructions && css`
        label{
            text-align: left;
            width: 60%;
            @media ${device.mobile}{
                width: 90%;
            }
        }
    `}
`

export const Submit = styled.button`
    background: #05386b;
    border: none;
    color: #edf5e1;
    padding 8px;
    margin-top: 20px;
    font-family: Play, sans-serif;
    border-radius: 4px;
    margin-bottom: 20px;
    ${props => props.secondary && css`
        margin-left: 10px;
        background: white;
        border: 1px solid #05386b;
        color: #05386b;
    `}
    ${props => props.disabled && css`
        border: 1px solid grey;
        color: white;
        background: grey;
    `}
    @media ${device.mobile} {
        margin: 10px;
    }
    align-self: center;
`


export const Image = styled.img`
  ${props => props.thumb && css`
    width: 100px;
    height: 100px;
    margin-right: 20px;
    border-radius: 4px;
    @media ${device.mobile} {
      width: 60px;
      height: 60px;
  }
  `}
`

export const ThumbImageDel = styled.img`
    width: 20px;
    margin-left: auto;
    margin-right: 20px;
`
