import { combineReducers } from 'redux'
import { App } from './app.reducer'
import { Listings } from './listings.reducer'
import { Cart } from './cart.reducer'
import { Shop } from './shop.reducer'
import { withReduxStateSync } from 'redux-state-sync'

const appReducer = combineReducers({
  App,
  Listings,
  Cart,
  Shop
})

const rootReducer = (state, action) => {
  return appReducer(state, action)
}

export default withReduxStateSync(rootReducer)