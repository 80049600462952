import { call, put, takeEvery, takeLatest, select } from 'redux-saga/effects'
import * as Actions from '../actions/action_types'
import Api from '../api'
import util from '../../utils/common'


const getProducts = state => state.Shop.products

function* addShop({ payload }) {
    try {
        const userData = !payload.editMode ? yield call(Api.addShop, payload) : yield call(Api.updateShopLogo, payload)
        yield put({ type: Actions.PUT_UPDATE_USER, ...userData })
    } catch (e) {
        yield put({ type: Actions.PUT_UPDATE_USER, ...e.response.data })
    }
}

function* getShopListings({ payload }) {
    try {
        const userData = yield call(Api.getShopListings, payload)
        yield put({ type: Actions.PUT_SHOP_LISTINGS, ...userData })
    } catch (e) {
        yield put({ type: Actions.PUT_SHOP_LISTINGS, ...e.response.data })
    }
}

function* putShopListings({ payload }) {
    try {
        const userData = yield call(Api.putShopListings, payload)
        yield put({ type: Actions.PUT_ADD_SHOP_LISTINGS, ...userData })
    } catch (e) {
        yield put({ type: Actions.PUT_ADD_SHOP_LISTINGS, ...e.response.data })
    }
}

function* provideShippingInfo({ payload }) {
    try {
        const shippingInfo = yield call(Api.provideShippingInfo, payload)
        // Update order in state
        var products = yield select(getProducts)
        if (!shippingInfo.err) {
            const filteredOrders = products.Orders.map(item => item.id == shippingInfo.data.id ? shippingInfo.data : item)
            products = { Listings: products.Listings, Orders: filteredOrders }
        }

        yield put({ type: Actions.PUT_SHOP_LISTINGS, ...{ data: products, err: shippingInfo.err } })
    } catch (e) {
        console.log("e", e)
        yield put({ type: Actions.PUT_SHOP_LISTINGS, ...e.response.data })
    }
}

function* createStripeAccount({ payload }) {
    try {
        const stripeInfo = yield call(Api.createStripeAccount, payload)

        yield put({ type: Actions.PUT_CREATE_STRIPE_ACCOUNT, ...stripeInfo })
    } catch (e) {
        console.log("e", e)
        yield put({ type: Actions.PUT_CREATE_STRIPE_ACCOUNT, ...e.response.data })
    }
}

function* retrieveStripeAccount({ payload }) {
    try {
        const stripeInfo = yield call(Api.retrieveStripeAccount, payload)

        yield put({ type: Actions.PUT_RET_STRIPE_ACCOUNT, ...stripeInfo })
    } catch (e) {
        console.log("e", e)
        yield put({ type: Actions.PUT_RET_STRIPE_ACCOUNT, ...e.response.data })
    }
}



function* listingsSaga() {
    yield takeLatest(Actions.ADD_SHOP, addShop)
    yield takeLatest(Actions.GET_SHOP_LISTINGS, getShopListings)
    yield takeLatest(Actions.ADD_SHOP_LISTINGS, putShopListings)
    yield takeLatest(Actions.PROVIDE_ORDER_SHIPPING_INFO, provideShippingInfo)
    yield takeLatest(Actions.CREATE_STRIPE_ACCOUNT, createStripeAccount)
    yield takeLatest(Actions.RET_STRIPE_ACCOUNT, retrieveStripeAccount)
}

export default listingsSaga