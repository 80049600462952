import { useState } from 'react';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { Submit, Wrapper, GlobalStyle } from './styles'
import { stripePromise } from '../../PageComponents/Cart'
// Custom styling can be passed to options when creating an Element.
const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: '#32325d',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
                color: '#aab7c4'
            }
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a'
        }
    }
};

const CheckoutForm = props => {
    const [error, setError] = useState(null);
    const stripe = useStripe();
    const elements = useElements();

    // Handle real-time validation errors from the card Element.
    const handleChange = (event) => {
        if (event.error) {
            setError(event.error.message);
        } else {
            setError(null);
        }
    }

    // Handle form submission.
    const handleSubmit = async (event) => {
        event.preventDefault();
        const card = elements.getElement(CardElement);
        const result = await stripe.createToken(card);
        if (result.error) {
            // Inform the user if there was an error.
            setError(result.error.message);
        } else {
            setError(null);
            // Send the token to your server.
            props.onSubmit(result.token.id)
        }
    }

    return (
        <div>
            <Wrapper>
                <CardElement
                    id="card-element"
                    options={CARD_ELEMENT_OPTIONS}
                    onChange={handleChange} />
            </Wrapper>
            <div className="card-errors" role="alert">{error}</div>
            <Wrapper >
                <Submit onClick={handleSubmit} > Add</Submit>
                <Submit secondary onClick={() => props.onCancel()} > Cancel</Submit>
            </Wrapper>
        </div>
    )
}

const App = props => {
    const { onCancel, onSubmit } = props
    return (
        <>
            <GlobalStyle />
            <Elements stripe={stripePromise}>
                <CheckoutForm onSubmit={onSubmit} onCancel={onCancel} />
            </Elements>
        </>
    )
}

export default App