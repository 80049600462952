import { createStore, applyMiddleware, compose } from 'redux'
import { createStateSyncMiddleware, initStateWithPrevTab } from 'redux-state-sync'
import reducer from './reducers/index'
import createSagaMiddleware from 'redux-saga'

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const config = {

  // whitelist: ['App']
  whitelist: ['Cart']
}

const persistConfig = {
  key: 'root',
  storage,
  // whitelist: ['App']
  whitelist: ['Cart']
}

const persistedReducer = persistReducer(persistConfig, reducer)

const middlewares = [
  createStateSyncMiddleware(config)
]

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const sagaMiddleware = createSagaMiddleware()
const store = createStore(persistedReducer, composeEnhancer(applyMiddleware(sagaMiddleware, ...middlewares)))
const persistor = persistStore(store)
initStateWithPrevTab(store)
export { store, persistor, middlewares, sagaMiddleware }