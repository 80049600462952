import * as actionType from './action_types'

export const AddShop = (data) => {
    return {
        type: actionType.ADD_SHOP,
        payload: data
    }
}

export const GetShopListings = (data) => {
    return {
        type: actionType.GET_SHOP_LISTINGS,
        payload: data
    }
}

export const AddShopListings = (data) => {
    return {
        type: actionType.ADD_SHOP_LISTINGS,
        payload: data
    }
}

export const ClearAddShopListings = (data) => {
    return {
        type: actionType.PUT_ADD_SHOP_LISTINGS,
        data: null,
        err: null
    }
}

export const prodvideOrderShipping = (data) => {
    return {
        type: actionType.PROVIDE_ORDER_SHIPPING_INFO,
        payload: data
    }
}

export const createStripeAccount = () => {
    return {
        type: actionType.CREATE_STRIPE_ACCOUNT,
        payload: {}
    }
}

export const retrieveStripeAccount = () => {
    return {
        type: actionType.RET_STRIPE_ACCOUNT,
        payload: {}
    }
}