const functions = {
  getUrlParams: () => {
    const url = window.location.href.split('?')[1]
    const urlParams = new URLSearchParams(url)
    const paramsObj = Object.fromEntries(urlParams)
    return paramsObj
  },
  getResponseKey: (path, response) => {
    if (Array.isArray(path)) {
      return path.reduce((obj, key) => (obj && obj[key]) ? obj[key] : null, response)
    }
    return null
  }

}

export default functions