import React, { useEffect, useState } from 'react'
import {
    Background, Title, Row, TotalSection, LabelDesciption, QuantityWrapper
} from './styles'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Actions from '../../redux/actions'
import util from '../../utils/common'
import Alert from 'react-bootstrap/Alert'
// import firebase from 'firebase'
// import Loader from 'react-loader-spinner'

// firebase.messaging().getToken(token => { console.log('token', token) })
const Checkout = props => {

    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [user, setUser] = useState(false)

    useEffect(() => {
        if (util.getResponseKey(['app', 'user', 'data'], props)) {
            setLoading(false)
            setUser(props.app.user.data)
        }

        if (util.getResponseKey(['app', 'user', 'err'], props)) {
            setError(props.app.user.err)
        }
    }, [props.app.user])

    useEffect(() => {
        // Check response from checkout API
        const checkoutSuccess = util.getResponseKey(['app', 'verifyCheckout'], props)
        if (checkoutSuccess) {
            setLoading(false)
            console.log('checkoutVerified', checkoutSuccess)

            if (checkoutSuccess.session.payment_status === 'paid') {
                props.actions.clearStripeSession(true)
            }
        }

    }, [props.app.verifyCheckout])

    useEffect(() => {

        const urlParams = util.getUrlParams()
        const sessionId = urlParams.session_id
        const cancel_session_id = urlParams.cancel_session_id
        const payment = urlParams.payment
        // Came from checkout
        if (sessionId) {
            props.actions.checkoutSuccess({ sessionId })
        } else if (payment === 'success') {
            props.actions.clearStripeSession(true)
        } else if (payment === 'paymentError') {
            props.actions.checkoutFailed({ cancel_session_id })
            props.actions.clearStripeSession(false)
            props.history.push('/cart')
        }

    }, [])

    if (error) {
        setTimeout(() => { setError(false) }, 4000)
    }

    if (loading) {
        return (
            <div>Loading</div>
        )
    }

    return (
        <Background id={'background'}>
            {/* <Title main> {`Welcome ${name}`} </Title> */}
            {error !== false && <Alert variant={'info'}> {error} </Alert>}

        </Background >
    )

}


const mapStateToProps = state => ({
    app: state.App,
    cart: state.Cart.items,
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
})

export default connect(mapStateToProps,
    mapDispatchToProps)(Checkout)