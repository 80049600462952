const desktop = '(min-width : 1025px)'
const tablet = '(min-width : 701px) and (max-width : 1024px)'
const mobile = '(max-width : 700px)'
export const device = { tablet, mobile, desktop }

export const color = {
  green: '#28a745',
  lightBlue: '#17a2b8',
  black: '#343a40',
  grey: '#6c757d',
  lightGreen: '#29dda7',
  blue: '#05386b',
  orange: '#fb7633',
  yellow: '#e8d317',
  red: '#d62b0d',
  darkGreen: '#0d4741',
  cream: '#edf5e1',
  bgGreen: '#5cde95',
  pink: '#e7717d'
}