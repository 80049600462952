import React, { useEffect, useState } from 'react'
import {
    Background, Title, Row, TotalSection, LabelDesciption, QuantityWrapper, SectionLabels, InputField, Submit
} from './styles'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Actions from '../../redux/actions'
import util from '../../utils/common'
import Alert from 'react-bootstrap/Alert'
// import Loader from 'react-loader-spinner'
import firebase from '../../firebase'
import Quantity from '../../Components/Quantity'
import DeleteImage from '../../assets/delete.svg'
import Login from '../../PageComponents/Login'

import { loadStripe } from '@stripe/stripe-js'
import { StripeKey } from '../../utils/constants'
import AutoComplete from '../../Components/AutoComplete'

const stripePromise = loadStripe(StripeKey)

const Cart = props => {

    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [cartEmpty, setCartEmpty] = useState(false)
    const [total, setTotal] = useState(0)
    const [shipping, setShipping] = useState(0)
    const [cartItems, setCartItems] = useState([])
    const [name, setName] = useState('')
    const [address, setAddress] = useState('')
    const [user, setUser] = useState(false)
    const [phone, setPhone] = useState('+61')
    const [editedAddress, setEditedAddress] = useState(false)

    useEffect(() => {
        if (util.getResponseKey(['app', 'user', 'data'], props)) {
            setLoading(false)
            setUser(props.app.user.data)
            setAddress(props.app.user.data.Address)
            setName(props.app.user.data.Name)
        }

        if (util.getResponseKey(['app', 'user', 'err'], props)) {
            setError(props.app.user.err)
        }
    }, [props.app.user])


    useEffect(async () => {
        if (props.stripeSession) {
            /* Payment charged using exising credit card */
            if (props.stripeSession.object === "payment_intent") {
                props.stripeSession.amount == (calcTotal() * 100) && props.history.push('/checkout?payment=success')
            } else {
                const stripe = await stripePromise
                const result = await stripe.redirectToCheckout({
                    sessionId: props.stripeSession.id,
                })
                if (result.error) {
                    // If `redirectToCheckout` fails due to a browser or network
                    // error, display the localized error message to your customer
                    // using `result.error.message`.
                    console.log('stripe error', result.error.message)
                }
            }
        }
    }, [props.stripeSession])

    useEffect(() => {
        if (!props.cart || props.cart.length === 0) {
            setCartEmpty(true)
        } else {
            setCartItems(props.cart)
            setCartEmpty(false)
        }
    }, [props.cart])

    useEffect(() => {
        props.err && setError(props.err)
    }, [props.err])

    useEffect(() => {
        if (!props.cart || props.cart.length === 0) {
            setLoading(false)
            return
        }
        firebase.auth().onAuthStateChanged(_ => {

            if (firebase.auth().currentUser) {
                // setLoading(true)
            } else {
                loading && setLoading(false)
            }
        })

        props.actions.VerifyCartPrices({ items: props.cart })
    }, [])

    if (error) {
        setTimeout(() => { setError(false) }, 4000)
    }

    if (loading) {
        return (
            <Background id={'background'}>
                <div>Loading</div>
            </Background>
        )
    }

    const calcTotal = () => {
        var totalLo = 0.00
        cartItems.length > 0 && cartItems.forEach(item => {
            totalLo += (item.Price * (item.qty || 1))
        })
        total !== (totalLo + shipping) && setTotal(totalLo + shipping)
        return (totalLo + shipping).toFixed(2)
    }

    const calcShipping = () => {
        var shippingLo = 0
        if (cartItems.length > 0) {
            cartItems.forEach(item => {
                shippingLo += (item.Shipping || 0) * item.qty
            })
        }
        shipping !== shippingLo && setShipping(shippingLo)
        return shippingLo.toFixed(2)
    }

    const updateQuantity = (val, id) => {
        var tempCartItems = cartItems
        const index = cartItems.findIndex(item => item.id === id)
        if (index >= 0) {
            tempCartItems[index].qty = val
            props.actions.updateCart([...tempCartItems])
        }
    }

    const removeItem = id => {
        const newCartItems = cartItems.filter(item => item.id !== id)
        props.actions.updateCart([...newCartItems])
    }

    return (
        <Background id={'background'}>
            {error !== false && <Alert variant={'info'}> {error} </Alert>}
            <div >
                <Row column main>
                    {cartEmpty ? <Title main> No items </Title> :
                        <>
                            <Title main> {`Your Cart`} </Title>
                            {cartItems && cartItems.length > 0 && cartItems.map(item =>
                                <div key={item.id}
                                    style={{ padding: '12px', flexDirection: 'column', display: 'flex', width: '90%', borderRadius: '12px', border: '1px solid #dcdcdc', marginBottom: '10px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}>
                                    <img src={DeleteImage} name='delete-item' onClick={() => { removeItem(item.id) }} style={{ marginLeft: 'auto', width: '25px', height: '25px' }} />
                                    <Row change>
                                        <img src={item.CoverImage} style={{ marginLeft: 'auto', marginRight: 'auto', borderRadius: '8px', width: '120px', height: '120px' }} />
                                        <Row column>
                                            <LabelDesciption Title onClick={({ target: { name } }) => (name !== 'delete-item' && name !== 'quantity') && props.history.push(`/view/${item.Title.trim().replace(/\s/g, '-')}?id=${item.id}`)} > {item.Title} </LabelDesciption>
                                            {item.qty > 1 && <LabelDesciption > {`$${item.Price}/item`} </LabelDesciption>}
                                            {item.StockRemaining > 1 &&
                                                <QuantityWrapper>
                                                    <LabelDesciption qty>Quantity: </LabelDesciption>
                                                    <Quantity value={item.qty || 1} maxValue={item.StockRemaining} setValue={(val) => updateQuantity(val, item.id)} />
                                                </QuantityWrapper>}
                                        </Row>
                                        <LabelDesciption price> {`$${(item.Price * item.qty).toFixed(2)}`} </LabelDesciption>
                                    </Row>
                                </div>
                            )}
                            <TotalSection shipping>
                                <LabelDesciption price shipping> {`Shipping: $${calcShipping()}`} </LabelDesciption>
                            </TotalSection>
                            <TotalSection>
                                <LabelDesciption price> {`Total: $${calcTotal()}`} </LabelDesciption>
                            </TotalSection>
                            {user && <div style={{ padding: '20px', width: '90%', borderRadius: '12px', flexDirection: 'column', display: 'flex', border: '1px solid #dcdcdc', marginBottom: '10px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}>
                                <SectionLabels main> Shipping Details   </SectionLabels>
                                <Row change>
                                    <SectionLabels> {'Name: '} </SectionLabels>
                                    <InputField onChange={({ target }) => setName(target.value)} value={name} />
                                </Row>

                                <Row change noBottom={editedAddress}>
                                    <SectionLabels> {'Address: '} </SectionLabels>
                                    <InputField onKeyDown={() => { !editedAddress && setEditedAddress(true) }} onBlur={() => setTimeout(() => setEditedAddress(false), 500)} onChange={({ target }) => setAddress(target.value)} value={address} />

                                </Row>
                                {editedAddress && <Row change>
                                    <SectionLabels> {''} </SectionLabels>
                                    <AutoComplete address={address} setAddress={setAddress} setEditedAddress={setEditedAddress} editedAddress={editedAddress} />

                                </Row>}
                                <Row change>
                                    <SectionLabels> {'Phone:'} </SectionLabels>
                                    <InputField onChange={({ target }) => target.value.length >= 3 && setPhone(target.value)} value={phone} />
                                </Row>
                                <Row change>
                                    <SectionLabels style={{ width: '350px', textAlign: 'center', alignSelf: 'center' }}> {'Phone # is required to send tracking information.'} </SectionLabels>
                                </Row>
                                <Submit onClick={() => props.actions.checkout({ items: cartItems, address, name })}> Checkout </Submit>
                            </div>}
                        </>
                    }

                </Row>
                {!user && !cartEmpty && cartItems && cartItems.length > 0 &&
                    <Row column main><div style={{ padding: '12px', width: '90%', flexDirection: 'column', display: 'flex', borderRadius: '12px', border: '1px solid #dcdcdc', marginBottom: '10px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}>

                        <SectionLabels main> {`Login or Signup to Checkout`} </SectionLabels>
                        <SectionLabels disclaimer> {`We promise to ask no more information than required 😊 `} </SectionLabels>
                        <Login fromCart history={props.history} />
                    </div>
                    </Row>}
            </div>
        </Background>
    )

}


const mapStateToProps = state => ({
    app: state.App,
    listings: state.Listings,
    shop: state.Shop,
    cart: state.Cart.items,
    err: state.Cart.err,
    stripeSession: state.Cart.stripeSession
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
})

export default connect(mapStateToProps,
    mapDispatchToProps)(Cart)

export { stripePromise }