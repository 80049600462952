import firebase from 'firebase'

  // Your web app's Firebase configuration
  var firebaseConfig = {
    apiKey: "AIzaSyBAyG0kQlKYYMqK_dGjIoEMynOaq3Gny9w",
    authDomain: "ecommerce-web-91811.firebaseapp.com",
    databaseURL: "https://ecommerce-web-91811.firebaseio.com",
    projectId: "ecommerce-web-91811",
    storageBucket: "ecommerce-web-91811.appspot.com",
    messagingSenderId: "179871844042",
    appId: "1:179871844042:web:3508e75364159b5b7d5633",
    measurementId: "G-4VN617DMZB"
  }
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig)

  export default firebase